/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import sidekick from '@last-rev/contentful-sidekick-util';
import styles from './ModuleTextMedia.module.scss';
import Asset from '../Asset';
import ImageExpand from '../ImageExpand';
import RichTextParser from '../RichTextParser';
import AssetPropTypes from '../Asset/AssetPropTypes';
import RichTextParserPropTypes from '../RichTextParser/RichTextParserPropTypes';
import ElementWistiaVideo, { ElementWistiaVideoPropTypes } from '../ElementWistiaVideo';

export const ModuleTextMediaPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleTextSize: PropTypes.string,
  body: PropTypes.shape(RichTextParserPropTypes),
  image: PropTypes.shape(AssetPropTypes),
  imageExpand: PropTypes.bool,
  mediaAlignment: PropTypes.string.isRequired,
  video: PropTypes.shape(ElementWistiaVideoPropTypes),
  className: PropTypes.string
};

export const getMediaAlignmentClass = (mediaAlignment) => {
  switch (mediaAlignment) {
    case 'Right':
      return 'mediaAlignRight';

    case 'Left':
      return 'mediaAlignLeft';

    case 'Centered':
      return 'mediaAlignCentered';

    default:
      return 'mediaAlignLeft';
  }
};

export const getTextSizeClass = (titleTextSize) => {
  switch (titleTextSize) {
    case 'Small':
      return 'titleTextSizeSmall';

    case 'Medium':
      return 'titleTextSizeMedium';

    case 'Large':
      return 'titleTextSizeLarge';

    default:
      return 'titleTextSizeMedium';
  }
};

export const MediaCol = ({ _id, image, video, imageExpand }) =>
  (image || video) && (
    <div className={styles.mediaCol} data-testid="ModuleTextMedia-imageVideoWrap">
      <div style={{ position: 'relative' }}>
        {image ? (
          <Asset
            _id={_id}
            imageWrapClassName="imageColorWrap"
            imageClassName={styles.image}
            fieldName="image"
            {...image}
          />
        ) : (
          <ElementWistiaVideo {...video} />
        )}
        {imageExpand && image ? <ImageExpand image={image} _id={_id} /> : null}
      </div>
    </div>
  );

MediaCol.propTypes = {
  _id: PropTypes.string.isRequired,
  image: PropTypes.shape(AssetPropTypes),
  video: PropTypes.shape(ElementWistiaVideoPropTypes),
  imageExpand: PropTypes.bool
};

MediaCol.defaultProps = {
  image: null,
  video: null,
  imageExpand: false
};

function ModuleTextMedia({
  _id,
  /* _contentTypeId, */ title,
  titleTextSize,
  body,
  image,
  imageExpand,
  video,
  mediaAlignment,
  className
}) {
  const mediaAlignmentClass = getMediaAlignmentClass(mediaAlignment);
  const titleTextSizeClass = getTextSizeClass(titleTextSize);

  return (
    <section className={className} data-testid="ModuleTextMedia" {...sidekick(_id, null, null, 'Module - Text Media')}>
      <div className={styles.moduleContainerWrap}>
        <div
          data-testid="ModuleTextMediaContainer"
          className={`${styles[mediaAlignmentClass]} ${styles.moduleContainerRow}`}>
          <div className={styles.textCol}>
            <div className={styles.titleWrap} {...sidekick(_id, 'title')}>
              {titleTextSize !== 'Small' ? (
                <h2 className={styles[titleTextSizeClass]} data-testid="ModuleTextMedia-title">
                  {title}
                </h2>
              ) : (
                <h3 className={styles[titleTextSizeClass]} data-testid="ModuleTextMedia-title">
                  {title}
                </h3>
              )}
            </div>

            {body ? (
              <div className={styles.bodyWrap} {...sidekick(_id, 'body')}>
                <div className={styles.body} data-testid="ModuleTextMedia-body">
                  <RichTextParser document={body} />
                </div>
              </div>
            ) : null}
          </div>
          <MediaCol _id={_id} image={image} imageExpand={imageExpand} video={video} />
        </div>
      </div>
    </section>
  );
}

ModuleTextMedia.propTypes = ModuleTextMediaPropTypes;

ModuleTextMedia.defaultProps = {
  titleTextSize: null,
  body: null,
  image: null,
  imageExpand: false,
  video: null,
  className: ''
};

export default ModuleTextMedia;
