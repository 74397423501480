/* eslint-env browser, node */
import { API } from 'aws-amplify';

const apiName = process.env.ENDPOINT_NAME;
const path = '/logs/log';

const remoteEnabled = process.env.NODE_ENV === 'production';

const composeMessage = (level, ...msg) => {
  const messages = msg
    .map((m) => {
      if (typeof m === 'string') return m;
      if (m.stack) return m.stack;
      if (m.componentStack) return m.componentStack;
      return JSON.stringify(m);
    })
    .join('\r\r');
  return `[${level.toUpperCase()}][${typeof window === 'undefined' ? '[SSR]' : '[CSR]'}]: ${messages}`;
};

const logRemote = async (level, ...msg) => {
  if (!remoteEnabled) return;
  const message = composeMessage(level, ...msg);
  await API.post(apiName, path, {
    body: {
      message,
      timestamp: new Date().getTime()
    }
  });
};
const _log = (level, ...msg) => {
  console.log(`[${level}]`, ...msg);
  logRemote(level, ...msg);
};

const log = (...msg) => {
  _log('INFO', ...msg);
};

const info = (...msg) => {
  _log('INFO', ...msg);
};

const warn = (...msg) => {
  _log('WARN', ...msg);
};

const error = (...msg) => {
  _log('ERROR', ...msg);
};

const debug = (...msg) => {
  _log('DEBUG', ...msg);
};

const logger = {
  log,
  info,
  warn,
  error,
  debug
};

export default logger;
