/* eslint-disable react/no-danger */
import React from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';
import NextHead from 'next/head';
import mockProps from './Head.mock';
import getSeo from '../../utils/getMetaTags';

import { locales, defaultLocale } from '../../../i18n';

export const HeadPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  seo: PropTypes.object,
  title: PropTypes.string,
  canonicalUrl: PropTypes.string
};

function Head({ seo, title, canonicalUrl }) {
  const { asPath } = useRouter();
  const { lang } = useTranslation();

  const getBasePath = (path) => (path === '/' ? '' : path);

  const basePath = getBasePath(asPath.replace(RegExp(`^/${lang}`), ''));

  const metatags = getSeo(seo);

  var indexablePage = '';
  return (
    <NextHead>
      <title data-testid="Head-title" key="title">
        {title}
      </title>
      <link rel="preconnect" href="https://dropbox.com" />
      <link rel="preload" href="/fonts/SharpGroteskDBMedium20.woff2" as="font" crossOrigin="" type="font/woff2" />
      <link rel="preload" href="/fonts/SharpGroteskDBMedium22.woff2" as="font" crossOrigin="" type="font/woff2" />
      <link rel="preload" href="/fonts/SharpGroteskDBBook20.woff2" as="font" crossOrigin="" type="font/woff2" />
      <link rel="preload" href="/fonts/AtlasGrotesk-Regular.woff2" as="font" crossOrigin="" type="font/woff2" />
      <link rel="preload" href="/fonts/AtlasGrotesk-Medium.woff2" as="font" crossOrigin="" type="font/woff2" />

      {metatags && metatags.length ? metatags.map((tag) => {
           if(tag.name === 'robots') 
           {
             indexablePage = tag.content;
           }
           return <meta key={tag.name || tag.property} {...tag} />;
        }) 
        : null}

      {locales
        ? locales.map((language) => {
            if (language === lang) return null; // skip current language
            const lowerCaseLanguage = language.toLowerCase();
            const url = `${process.env.HOST}${language === defaultLocale ? '' : `/${lowerCaseLanguage}`}${basePath}`;
            if(!indexablePage.includes('noindex'))
            return <link href={url} rel="alternate" hrefLang={lowerCaseLanguage} key={lowerCaseLanguage} />;
          })
        : null}

      {canonicalUrl ? (
        <link rel="canonical" href={!canonicalUrl.endsWith('/') ? canonicalUrl : canonicalUrl.slice(0, -1)} />
      ) : null}

      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="20x20" href="/touch-icon-20x20.png" />
      <link rel="apple-touch-icon" sizes="29x29" href="/touch-icon-29x29.png" />
      <link rel="apple-touch-icon" sizes="40x40" href="/touch-icon-40x40.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/touch-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/touch-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="167x167" href="/touch-icon-167x167.png" />
      <link rel="apple-touch-icon" sizes="512x512" href="/touch-icon-512x512.png" />
      <meta name="contentful_space" content={process.env.CONTENTFUL_SPACE_ID} key="spaceId" />
      <meta name="contentful_environment" content={process.env.CONTENTFUL_ENV} key="contentfulEnv" />
      <meta name="theme-color" content="#3372e6" />
    </NextHead>
  );
}

Head.propTypes = HeadPropTypes;

Head.defaultProps = {
  ...mockProps,
  seo: {},
  title: '',
  canonicalUrl: null
};

export default Head;
