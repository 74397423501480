import React, { useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import map from 'lodash/map';
// import minutesToHours from './minutesToHours';

const useTopicDetails = (topics) => {
  const { t } = useTranslation();
  const topicHash = useMemo(() => map(topics, '_id').join('-'), [topics]);
  return useMemo(() => {
    let topicString;
    if (topics?.length) {
      topicString = `${topics.length} ${
        topics.length === 1 ? t('common:topicCountTextSingle') : t('common:topicCountTextPlural')
      }`;
    }

    // const readTime = Object.values(topics).reduce((total, { readingTime }) => total + readingTime, 0);
    // const readTimeObj = minutesToHours(readTime);

    // const readTimeArr = [];
    // if (readTimeObj.hours && readTimeObj.hoursLookupShort) {
    //   const hoursText = t(`common:${readTimeObj.hoursLookupShort}`);
    //   readTimeArr.push(`${readTimeObj.hours}${hoursText}`);
    // }

    // if (readTimeObj.minutes && readTimeObj.minutesLookupShort) {
    //   const minsText = t(`common:${readTimeObj.minutesLookupShort}`);
    //   readTimeArr.push(`${readTimeObj.minutes}${minsText}`);
    // }

    // if (readTimeArr.length) readTimeArr.push(t(`common:textRead`));

    // const bullet = topicString && readTimeArr.length ? String.fromCharCode(8226) : ''; // Unicode bullet

    // return `${topicString} ${bullet} ${readTimeArr.join(' ')}`;
    return topicString;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicHash]);
};

export default useTopicDetails;
