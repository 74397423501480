/* eslint-disable camelcase */
import axios from 'axios';
import moment from 'moment';
import filter from 'lodash/filter';
import map from 'lodash/map';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import { API } from 'aws-amplify';
import {
  ERROR,
  SET_CURRENT_EVENT_REGISTRATION,
  SELECT_LANGUAGE,
  SELECT_DATE,
  SELECT_TIME,
  COMPLETING_EVENT_REGISTRATION,
  COMPLETED_EVENT_REGISTRATION,
  CANCELING_EVENT_REGISTRATION,
  CANCELED_EVENT_REGISTRATION,
  LOADING_EVENT_REGISTRATIONS,
  LOADED_EVENT_REGISTRATIONS
} from './webinars.types';

const apiName = process.env.ENDPOINT_NAME;
const dropboxRestEndpoint = process.env.DROPBOX_REST_ENDPOINT;

const getSrc = () => {
  let { host: src = '' } = global.location;
  if (/^localhost/.test(src)) {
    src = 'learn-stage.dropbox.com';
  }
  return src;
};

const availablePredicate = (occurrence) => occurrence.status === 'available';

export const selectLanguage =
  ({ selectedLanguage, selectedWebinarId }) =>
  async (dispatch) => {
    try {
      const path = '/zoom/webinar';
      const response = await API.get(apiName, path, {
        queryStringParameters: {
          webinar_id: selectedWebinarId
        }
      });

      let { occurrences = [] } = response;

      occurrences = filter(occurrences, availablePredicate);

      const availableDates = uniq(map(occurrences, (occurrence) => moment(occurrence.start_time).format('YYYY-MM-DD')));

      dispatch({
        type: SELECT_LANGUAGE,
        payload: {
          selectedLanguage,
          selectedWebinarId,
          occurrences,
          availableDates
        }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: {
          error: `${error}`
        }
      });
    }
  };

export const setCurrentEventRegistration =
  ({ reschedulingWebinarId, reschedulingOccurrenceId } = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_CURRENT_EVENT_REGISTRATION,
      payload: {
        reschedulingWebinarId,
        reschedulingOccurrenceId
      }
    });
  };

export const selectDate =
  ({ selectedDate }) =>
  async (dispatch, getState) => {
    const {
      webinars: {
        current: { occurrences }
      }
    } = getState();

    const availableTimes = uniq(
      map(
        filter(occurrences, (occurrence) => selectedDate === moment(occurrence.start_time).format('YYYY-MM-DD')),
        (occurrence) => moment(occurrence.start_time).format('h:mm a z')
      )
    );

    if (availableTimes.length === 0) {
      dispatch({
        type: ERROR,
        payload: {
          error: 'No available times for that selection'
        }
      });
      return;
    }

    dispatch({
      type: SELECT_DATE,
      payload: {
        selectedDate,
        availableTimes
      }
    });
  };

export const selectTime =
  ({ selectedTime }) =>
  async (dispatch, getState) => {
    const {
      webinars: {
        current: { occurrences, selectedDate }
      }
    } = getState();

    try {
      const { occurrence_id } = find(occurrences, (occurrence) => {
        const current = moment(occurrence.start_time);
        return current.format('YYYY-MM-DD') === selectedDate && current.format('h:mm a z') === selectedTime;
      });
      dispatch({
        type: SELECT_TIME,
        payload: {
          selectedTime,
          selectedOccurrenceId: occurrence_id
        }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: {
          error: `${error}`
        }
      });
    }
  };

export const loadEventRegistrations = () => async (dispatch, getState) => {
  try {
    const {
      user: { id: userId }
    } = getState();
    if (!userId) return;
    dispatch({
      type: LOADING_EVENT_REGISTRATIONS
    });
    const path = '/webinarregistrations/list';
    const response = await API.get(apiName, path, {
      queryStringParameters: {
        user_id: userId
      }
    });

    const { items: eventRegistrations } = response;

    dispatch({
      type: LOADED_EVENT_REGISTRATIONS,
      payload: {
        eventRegistrations
      }
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: {
        error: `${error}`
      }
    });
  }
};

export const cancelRegistration =
  ({ webinarId, occurrenceId }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CANCELING_EVENT_REGISTRATION
      });
      await axios({
        method: 'post',
        url: `${dropboxRestEndpoint}/cancel_webinar_registration`,
        data: {
          webinar_id: parseInt(webinarId, 10),
          occurrence_id: occurrenceId,
          src: getSrc()
        },
        withCredentials: true
      });
      dispatch({
        type: CANCELED_EVENT_REGISTRATION,
        payload: {
          webinarId,
          occurrenceId
        }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: {
          error: `${error}`
        }
      });
    }
  };

export const register = () => async (dispatch, getState) => {
  const {
    webinars: {
      current: { selectedWebinarId, selectedOccurrenceId, reschedulingOccurrenceId, reschedulingWebinarId }
    },
    user: { id: userId }
  } = getState();
  if (!selectedWebinarId || !selectedOccurrenceId) {
    dispatch({
      type: ERROR,
      payload: {
        error: `missing one of[ webinarId: ${selectedWebinarId}, occurrenceId: ${selectedOccurrenceId}]`
      }
    });
    return;
  }
  if (reschedulingOccurrenceId && reschedulingWebinarId) {
    await dispatch(cancelRegistration({ webinarId: reschedulingWebinarId, occurrenceId: reschedulingOccurrenceId }));
  }
  try {
    dispatch({
      type: COMPLETING_EVENT_REGISTRATION
    });
    await axios({
      method: 'post',
      url: `${dropboxRestEndpoint}/register_for_webinar`,
      data: {
        webinar_id: parseInt(selectedWebinarId, 10),
        occurrence_id: selectedOccurrenceId,
        custom_questions: {
          user_id: userId
        },
        src: getSrc()
      },
      withCredentials: true
    });
    dispatch({
      type: COMPLETED_EVENT_REGISTRATION
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error && `${error}`.includes('409') ? { webinarFull: true } : { error: `${error}` }
    });
  }
};
