import React, { useEffect } from 'react';
import ReactCalendar from 'react-calendar';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { connect } from 'react-redux';
import moment from 'moment';
import styles from './Calendar.module.scss';
import { selectDate } from '../../redux/modules/webinars';
import Asset from '../Asset';
import { localesWithDifferentSettings } from '../../utils/reactCalendarFunctions';

const mapStateToProps = (state) => {
  const {
    webinars: {
      current: { availableDates, selectedDate, selectedLanguage }
    }
  } = state;
  return {
    availableDates,
    selectedDate,
    selectedLanguage
  };
};

const mapDispatchToProps = {
  dateSelectionHandler: selectDate
};

const Calendar = ({ availableDates, selectedDate, dateSelectionHandler, selectedLanguage }) => {
  const { t, lang } = useTranslation();

  useEffect(() => {
    if (!availableDates || !availableDates.length) return;
    dateSelectionHandler({ selectedDate: availableDates[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDates]);

  const tileClassName = ({ date }) => {
    const today = new Date();
    if (today > date) {
      return 'react-calendar__tile--past';
    }
    return null;
  };

  const determineIfTileDisabled = ({ date }) => {
    const dateStr = moment(date).format('YYYY-MM-DD');
    return availableDates.indexOf(dateStr) === -1;
  };

  const handleDateSelection = (date) => {
    dateSelectionHandler({ selectedDate: moment(date).format('YYYY-MM-DD') });
  };

  const value = selectedDate !== null ? moment(selectedDate).toDate() : moment(availableDates[0]).toDate();

  const NextArrow = <Asset url="/images/assets/icon-chevron-right.svg" title="Next Month" bypassOptimization />;
  const PrevArrow = <Asset url="/images/assets/icon-chevron-right.svg" title="Previous Month" bypassOptimization />;
  return (
    <div data-testid="Calendar" className={styles.moduleCalendar}>
      <label className={styles.label} data-testid="Calendar-header-text" htmlFor="calendar">
        {t('common:formInputLabelSelectDate')}
      </label>

      <div className={styles.calendarWrap}>
        <ReactCalendar
          name="calendar"
          view="month"
          minDetail="month"
          maxDetail="month"
          value={value}
          calendarType={!localesWithDifferentSettings.includes(selectedLanguage || lang) ? "US" : "ISO 8601"}
          tileClassName={tileClassName}
          tileDisabled={determineIfTileDisabled}
          onChange={handleDateSelection}
          locale={lang}
          minDate={new Date()}
          showNeighboringMonth={false}
          showWeekNumbers={false}
          nextLabel={NextArrow}
          prevLabel={PrevArrow}
          formatShortWeekday={(_, date) =>
            [
              t('common:textCalendarShortWeekdaySunday'),
              t('common:textCalendarShortWeekdayMonday'),
              t('common:textCalendarShortWeekdayTuesday'),
              t('common:textCalendarShortWeekdayWednesday'),
              t('common:textCalendarShortWeekdayThursday'),
              t('common:textCalendarShortWeekdayFriday'),
              t('common:textCalendarShortWeekdaySaturday')
              // eslint-disable-next-line react/prop-types
            ][date.getDay()]
          }
          prev2Label={null}
          next2Label={null}
        />
      </div>
    </div>
  );
};

Calendar.propTypes = {
  availableDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedDate: PropTypes.string,
  dateSelectionHandler: PropTypes.func.isRequired,
  selectedLanguage: PropTypes.string
};

Calendar.defaultProps = {
  selectedDate: null,
  selectedLanguage: null
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
