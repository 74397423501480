/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Asset from '../Asset';
import AssetPropTypes from '../Asset/AssetPropTypes';
import styles from './ImageExpand.module.scss';

export const ImageExpandPropTypes = {
  _id: PropTypes.string.isRequired,
  image: PropTypes.shape(AssetPropTypes).isRequired
};

function ImageExpand({ _id, image }) {
  const [showModal, setShowModal] = React.useState(false);

  const handleExpand = (expand, e) => {
    e.preventDefault();
    setShowModal(expand);
  };

  return (
    <div className={styles.module}>
      <a href="#" className={styles.imageExpand} onClick={(e) => handleExpand(true, e)}>
        <svg viewBox="-4 -4 32 32" fill="none" className="dig-UIIcon" width="32" height="32" focusable="false">
          <path d="M14.5 5V6.5H16.4395L13.7195 9.2195L14.7805 10.2805L17.5 7.5605V9.5H19V5H14.5Z" fill="#000" />
          <path d="M9.2195 13.7195L6.5 16.4395V14.5H5V19H9.5V17.5H7.5605L10.2805 14.7805L9.2195 13.7195Z" fill="#000" />
          <path
            d="M17.5 16.4395L14.7805 13.7195L13.7195 14.7805L16.4395 17.5H14.5V19H19V14.5H17.5V16.4395Z"
            fill="#000"
          />
          <path d="M9.5 6.5V5H5V9.5H6.5V7.5605L9.2195 10.2805L10.2805 9.2195L7.5605 6.5H9.5Z" fill="#000" />
        </svg>
      </a>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop
        scrollable
        centered
        size="xl"
        animation={false}
        className={styles.modalDialog}>
        <Modal.Body>
          <div className={styles.assetWrap}>
            <Asset _id={_id} {...image} />
          </div>
          <a href="#" className={cx(styles.imageExpand, styles.close)} onClick={(e) => handleExpand(false, e)}>
            <Asset url="/images/assets/icon-close-x.svg" title="Close image" isInlineSvg />
          </a>
        </Modal.Body>
      </Modal>
    </div>
  );
}

ImageExpand.propTypes = ImageExpandPropTypes;

export default ImageExpand;
