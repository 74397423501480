import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import ReactSlick from 'react-slick';
import PropTypes from 'prop-types';
import styles from './ModuleCarousel.module.scss';
import { ModuleCardPropTypes } from '../ModuleCard';
import ModuleCarouselSlide from './ModuleCarouselSlide/ModuleCarouselSlide';

export const ModuleCarouselPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  carouselSlides: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(ModuleCardPropTypes)])).isRequired,
  className: PropTypes.string
};

function ModuleCarousel({ _id, carouselSlides, className }) {
  const reactSlickSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <section className={className} data-testid="ModuleCarousel">
      <div className={styles.moduleCarousel}>
        <div className={styles.moduleContainerWrap} {...sidekick(_id, 'carouselSlides', null, 'Carousel Slides')}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ReactSlick {...reactSlickSettings}>
            {carouselSlides && carouselSlides.length
              ? carouselSlides.map((carouselSlide) => (
                  <ModuleCarouselSlide
                    _id={carouselSlide._id}
                    _contentTypeId={carouselSlide._contentTypeId}
                    headerText={carouselSlide.headerText}
                    summaryText={carouselSlide.summaryText}
                    image={carouselSlide.image}
                    cta={carouselSlide.cta}
                    summaryRichText={carouselSlide.summaryRichText}
                    imageExpand={carouselSlide.imageExpand}
                    key={carouselSlide._id}
                  />
                ))
              : null}
          </ReactSlick>
        </div>
      </div>
    </section>
  );
}
ModuleCarousel.propTypes = ModuleCarouselPropTypes;

ModuleCarousel.defaultProps = {
  className: ''
};

export default ModuleCarousel;
