import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import cx from 'clsx';
import kebabCase from 'lodash/kebabCase';
import styles from './LayoutSection.module.scss';
import RichTextParser from '../RichTextParser';
import RichTextParserPropTypes from '../RichTextParser/RichTextParserPropTypes';
import { ModuleHeroPropTypes } from '../ModuleHero';
import { ModuleCardPropTypes } from '../ModuleCard';
import { ModuleRichTextPropTypes } from '../ModuleRichText';
import ContentModule from '../ContentModule';

export const LayoutSectionPropTypes = {
  _id: PropTypes.string,
  headerText: PropTypes.string,
  introCopy: PropTypes.shape(RichTextParserPropTypes),
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(ModuleHeroPropTypes),
      PropTypes.shape(ModuleCardPropTypes),
      PropTypes.shape(ModuleRichTextPropTypes)
    ])
  ),
  columnLayout: PropTypes.string,
  sectionWidth: PropTypes.string,
  className: PropTypes.string
};

function LayoutSection({
  _id,
  headerText,
  introCopy,
  columns,
  className,
  columnLayout /* , columnLayout, sectionWidth */
}) {
  const isAnchorLink = (module) =>
    module && module._contentTypeId === 'elementLink' && module.destinationType === 'Set Anchor Tag';

  return (
    <section className={className} data-testid="LayoutSection">
      <div className={styles.moduleContainerWrap}>
        {headerText || introCopy ? (
          <div className={styles.sectionHeaderWrap}>
            {headerText ? (
              <div className={styles.sectionTitleWrap} {...sidekick(_id, 'headerText', null, 'Header Text')}>
                <h3 className={styles.sectionTitle}>{headerText}</h3>
              </div>
            ) : null}

            {introCopy ? (
              <div className={styles.introCopyWrap} {...sidekick(_id, 'introCopy', null, 'Intro Copy')}>
                <RichTextParser document={introCopy} />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className={styles.moduleContainerRow} {...sidekick(_id, 'columns', null, 'Columns')}>
          {columns && columns.length
            ? columns.map(
                (module, index) =>
                  !isAnchorLink(module) && (
                    <div
                      className={cx(
                        'sub-section',
                        styles.moduleCol,
                        styles[`moduleCol-${kebabCase(columnLayout.toLowerCase())}`]
                      )}
                      key={`${module._id}-col`}>
                      {isAnchorLink(columns[index - 1]) && (
                        <span id={columns[index - 1]?.linkText?.toLowerCase()?.replace(/ /g, '-')} />
                      )}
                      <ContentModule contentTypeId={module._contentTypeId} fields={module} />
                    </div>
                  )
              )
            : null}
        </div>
      </div>
    </section>
  );
}
LayoutSection.propTypes = LayoutSectionPropTypes;

LayoutSection.defaultProps = {
  _id: null,
  headerText: null,
  introCopy: null,
  columns: null,
  columnLayout: null,
  sectionWidth: null,
  className: ''
};

export default LayoutSection;
