export const localesWithDifferentSettings = ['de', 'es', 'fr', 'it'];

const getFormattedHours = (hour, amPm) => {
  if (!(hour && amPm)) return null;

  const parsedHour = parseInt(hour, 10);
  if (!parsedHour || parsedHour === 'NaN') return null;

  let formattedHour = '';
  if (amPm === 'am') {
    const format24Hour = parsedHour === 12 ? '0' : hour;
    formattedHour = `${parsedHour < 10 ? '0' : ''}${format24Hour}`;
  } else {
    formattedHour = (parsedHour < 12 ? parsedHour + 12 : parsedHour).toString();
  }
  return formattedHour;
};

export const get24HourTime = (time, lang) => {
  if (!(time && lang)) return time;
  if (!localesWithDifferentSettings.includes(lang)) return time;
  const timeArray = time.split(':');
  const hours = timeArray[0];
  const minutesArray = timeArray[1]?.split(' ');
  const minutes = minutesArray[0];
  const amPm = minutesArray[1];
  const formattedHours = getFormattedHours(hours, amPm);
  
  return formattedHours ? `${formattedHours}:${minutes}` : time;
};

