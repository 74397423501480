import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './ModuleMultiColumnVideos.module.scss';
import ElementVideo, { ElementVideoPropTypes } from '../ElementVideo';
import ElementLink from '../ElementLink';

export const ModuleMultiColumnVideosPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  videos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(ElementVideoPropTypes)])).isRequired,
  viewMoreLinkText: PropTypes.string.isRequired,
  className: PropTypes.string,
  themeClassName: PropTypes.string
};

function ModuleMultiColumnVideos({
  _id,
  /* _contentTypeId, */ title,
  subtitle,
  videos,
  viewMoreLinkText,
  className,
  themeClassName
}) {
  return (
    <section data-testid="ModuleMultiColumnVideos" className={cx(className, themeClassName, styles[themeClassName])}>
      <div className={styles.moduleContainerWrap}>
        <div className={styles.sectionHeaderWrap}>
          <div className={styles.sectionTitleWrap} {...sidekick(_id, 'title')}>
            <h2 className={styles.sectionTitle} data-testid="ModuleTitle">
              {title}
            </h2>
          </div>

          {subtitle ? (
            <div className={styles.subtitleWrap} {...sidekick(_id, 'subtitle')}>
              <h5 className={styles.subtitle} data-testid="ModuleSubtitle">
                {subtitle}
              </h5>
            </div>
          ) : null}
        </div>

        <div className={styles.moduleContainerRow}>
          {videos && videos.length
            ? videos.map((video) => (
                <div className={styles.videoCol} key={video._id}>
                  <div className={styles.videoWrap} data-testid="ModuleVideoWrap">
                    <ElementVideo
                      _id={video._id}
                      videoId={video.videoId}
                      title={video.description}
                      isYouTube={video.isYouTube}
                    />
                  </div>
                </div>
              ))
            : null}
        </div>

        {viewMoreLinkText && videos.length > 3 ? (
          <div className={styles.viewMoreTextLinkWrap}>
            <ElementLink
              href="/virtual-training"
              isModal={false}
              download={false}
              linkText={viewMoreLinkText}
              className={styles.viewMoreTextLink}>
              {viewMoreLinkText}
            </ElementLink>
          </div>
        ) : null}
      </div>
    </section>
  );
}
ModuleMultiColumnVideos.propTypes = ModuleMultiColumnVideosPropTypes;

ModuleMultiColumnVideos.defaultProps = {
  subtitle: null,
  className: '',
  themeClassName: ''
};

export default ModuleMultiColumnVideos;
