import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './ModuleHero.module.scss';
import Asset from '../Asset';
import AssetPropTypes from '../Asset/AssetPropTypes';
import { ElementLinkPropTypes } from '../ElementLink';

export const ModuleHeroPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  summaryText: PropTypes.string,
  image: PropTypes.shape(AssetPropTypes),
  cta: PropTypes.shape(ElementLinkPropTypes),
  className: PropTypes.string
};

function ModuleHero({ _id, _contentTypeId, headerText, summaryText, image, className }) {
  return (
    <section
      {...sidekick(_id, null, _contentTypeId, 'Module Hero')}
      data-testid="ModuleHero"
      className={cx(className, styles[className], styles.moduleHero, 'themeBgColor')}
      contenttype="moduleHero">
      <div className={styles.moduleContainerWrap}>
        <div className={styles.moduleContainerRow}>
          <div className={styles.textCol}>
            <div className={styles.headerTextWrap} {...sidekick(_id, 'headerText', _contentTypeId)}>
              <h1 className={styles.headerText} data-testid="ModuleHero-headerText">
                {headerText}
              </h1>
            </div>

            {summaryText ? (
              <div className={styles.summaryTextWrap} {...sidekick(_id, 'summaryText', _contentTypeId)}>
                <p className={styles.summaryText} data-testid="ModuleHero-summaryText">
                  {summaryText}
                </p>
              </div>
            ) : null}
          </div>

          {image && image.url ? (
            <div className={cx(styles.imageCol, image.url.includes('.svg') ? styles.heroImage : '')} data-testid="ModuleHero-image">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Asset _id={_id} fieldName="image" {...image} preload />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}
ModuleHero.propTypes = ModuleHeroPropTypes;

ModuleHero.defaultProps = {
  summaryText: null,
  image: null,
  cta: null,
  className: 'theme-default'
};

export default ModuleHero;
