import PropTypes from 'prop-types';

export default PropTypes.shape({
  nodeType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      nodeType: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      data: PropTypes.object.isRequired,
      marks: PropTypes.arrayOf(PropTypes.object).isRequired
    })
  ).isRequired
}).isRequired;
