/**
 * Per,
 * https://docs.coveo.com/en/105/cloud-v2-developers/api-key-authentication
 * an API token meant to be stored client-side and only has permission
 * to execute queries.
 *
 */
const getAPIToken = () => {
  return process.env.COVEO_API_KEY;
};

const getOrganizationId = () => {
  return process.env.COVEO_ORG_ID;
};

/* global fetch */
const coveoSearch = async (query = '', filters = '', limit = 10, after = 0) => {
  return fetch(`https://${getOrganizationId()}.org.coveo.com/rest/search/v2?organizationId=${getOrganizationId()}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${getAPIToken()}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      q: `${query.replace(/[[\]]/g, '')}`,
      aq: `(@source=="Customer Education") ${filters}`,
      numberOfResults: limit,
      firstResult: after,
      searchHub: 'customer-education'
    })
  });
};

export default coveoSearch;
