/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import keys from 'lodash/keys';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { useSelect } from 'downshift';
import { connect } from 'react-redux';
import cx from 'clsx';
import { selectLanguage } from '../../redux/modules/webinars';
import styles from './LanguageSelectionDropdown.module.scss';
import toPascalCase from '../../utils/toPascalCase';

const mapDispatchToProps = {
  handleLanguageSelection: selectLanguage
};

export const LanguageSelectionDropdownPropTypes = {
  languageZoomIDs: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  handleLanguageSelection: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired
};

const LanguageSelectionDropdown = ({ languageZoomIDs, handleLanguageSelection, slug }) => {
  const languageOfferings = keys(omitBy(languageZoomIDs, isNil));

  const handleLanguageChange = ({ selectedItem: selectedLanguage }) => {
    const selectedWebinarId = languageZoomIDs[selectedLanguage];
    handleLanguageSelection({ selectedLanguage, selectedWebinarId });
  };

  const { t, lang } = useTranslation();

  useEffect(() => {
    handleLanguageSelection({ selectedLanguage: lang, selectedWebinarId: languageZoomIDs[lang] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, languageZoomIDs]);

  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: languageOfferings,
    onSelectedItemChange: handleLanguageChange,
    id: `${slug}RegistrationLanguageSelection`,
    initialSelectedItem: lang
  });

  return (
    <div className={styles.moduleLanguageSelectionDropdown} data-testid="LanguageSelectionDropdown">
      <label className={styles.label} htmlFor="language" id="business-team-trainingRegistrationLanguageSelection-label">
        {t('common:formInputLabelPickLanguage')}
      </label>

      <button
        type="button"
        data-uxa-log="LanguageSelection-Button"
        data-uxa-interactions="click shown"
        {...getToggleButtonProps()}
        className={cx('custom-select', styles.select)}
        title={t('common:formInputLabelPickLanguage')}>
        {t(`common:languageName${toPascalCase(selectedItem || lang || 'en-US')}`)}
      </button>

      <ul
        {...getMenuProps()}
        className={cx(styles.selectOptions, { 'menu-open': isOpen })}
        data-testid="LanguageSelectionDropdown-field">
        {isOpen &&
          languageOfferings.map((language, index) => (
            <li id={`language-${language}`} languageid={language} key={language} {...getItemProps({ language, index })} data-uxa-log={`language_${language.toLowerCase().replace(/\s+/g, '_')}`} data-uxa-interactions="click shown">
              {t(`common:languageName${toPascalCase(language)}`)}
            </li>
          ))}
      </ul>
    </div>
  );
};

LanguageSelectionDropdown.propTypes = LanguageSelectionDropdownPropTypes;

export default connect(null, mapDispatchToProps)(LanguageSelectionDropdown);
