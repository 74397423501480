import axios from 'axios';
import { LOADING_USER_PROFILE, LOADED_USER_PROFILE, NOT_LOGGED_IN } from './user.types';
import { loadAllCompletedTopics, loadCompletedCourses } from '../courses';
import { loadEventRegistrations } from '../webinars';

// eslint-disable-next-line import/prefer-default-export
export const loadUserProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING_USER_PROFILE
      });

      const response = await axios({
        method: 'post',
        url: `https://www.dropbox.com/2/gromit/get_user_profile`,
        data: {},
        withCredentials: true
      });

      const {
        // eslint-disable-next-line camelcase
        data: { first_name, last_initial, user_xid }
      } = response;
      dispatch({
        type: LOADED_USER_PROFILE,
        payload: {
          id: user_xid,
          firstName: first_name,
          lastInitial: last_initial
        }
      });
      dispatch(loadCompletedCourses());
      dispatch(loadAllCompletedTopics());
      dispatch(loadEventRegistrations());
    } catch (error) {
      dispatch({
        type: NOT_LOGGED_IN
      });
    }
  };
};
