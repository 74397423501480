const prefix = 'learn.dropbox.com/eventregistration';

export const ERROR = `${prefix}/ERROR`;
export const SET_CURRENT_EVENT_REGISTRATION = `${prefix}/SET_CURRENT_EVENT_REGISTRATION`;
export const SELECT_LANGUAGE = `${prefix}/SELECT_LANGUAGE`;
export const SELECT_DATE = `${prefix}/SELECT_DATE`;
export const SELECT_TIME = `${prefix}/SELECT_TIME`;
export const COMPLETING_EVENT_REGISTRATION = `${prefix}/COMPLETING_EVENT_REGISTRATION`;
export const COMPLETED_EVENT_REGISTRATION = `${prefix}/COMPLETED_EVENT_REGISTRATION`;
export const CANCELING_EVENT_REGISTRATION = `${prefix}/CANCELING_EVENT_REGISTRATION`;
export const CANCELED_EVENT_REGISTRATION = `${prefix}/CANCELED_EVENT_REGISTRATION`;
export const LOADING_EVENT_REGISTRATIONS = `${prefix}/LOADING_EVENT_REGISTRATIONS`;
export const LOADED_EVENT_REGISTRATIONS = `${prefix}/LOADED_EVENT_REGISTRATIONS`;
