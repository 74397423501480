import React, { useState, useEffect, useCallback } from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import difference from 'lodash/difference';
import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import keys from 'lodash/keys';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import cx from 'clsx';
import styles from './PageCourse.module.scss';
import Asset from '../Asset';
import ElementLink from '../ElementLink';
import useCourseImage from '../../utils/useCourseImage';
import getThemeClass from '../../utils/getThemeClass';
import useTopicDetails from '../../utils/useTopicDetails';
import PageCoursePropTypes from './PageCoursePropTypes';
import TopicLink from '../TopicLink';
import CourseFinishedPopup from '../CourseFinishedPopup';
import { setViewedCompletiondMessageForCourse, completeCourse } from '../../redux/modules/courses';

const mapStateToProps = (state) => ({
  completedCourseTopics: state.courses.completedCourseTopics,
  completedCourses: state.courses.completed,
  completedCoursesLoaded: state.courses.loaded,
  completedCourseTopicsLoaded: state.courses.loadedCourseTopics,
  isLoggedIn: !!state.user.id
});

const mapDispatchToProps = {
  markCompletionMessageShown: setViewedCompletiondMessageForCourse,
  markCourseAsCompleted: completeCourse
};

function PageCourse({
  _id,
  _href,
  _as,
  _contentTypeId,
  // slug,
  name,
  category,
  image,
  thumbnail,
  description,
  objectives,
  // expectations,
  topics,
  courseCompleteCta,
  courseCompleteHeaderText,
  courseCompleteBody,
  courseCompleteImage,
  // redux state below
  completedCourseTopics,
  completedCourses,
  completedCoursesLoaded,
  completedCourseTopicsLoaded,
  // isLoggedIn,
  // redux actions below
  markCompletionMessageShown,
  markCourseAsCompleted
}) {
  const [enableCompletionPopup, setEnableCompletionPopup] = useState(false);
  const [showAllTopics, setShowAllTopics] = useState('');
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [nextTopic, setNextTopic] = useState(null);
  const [courseStartCtaTextKey, setCourseStartCtaTextKey] = useState('common:textStartCourse');
  const [showNextTopicLink, setShowNextTopicLink] = useState(true);
  const [completedCoursesCalculationsCompleted, setCompletedCoursesCalculationsCompleted] = useState(false);
  const [courseHasBeenMarkedCompleted, setCourseHasBeenMarkedCompleted] = useState(false);
  const [completedTopicIds, setCompletedTopicIds] = useState([]);

  const triggerCompletionMessage = useCallback(() => {
    setEnableCompletionPopup(true);
    markCompletionMessageShown(_id);
  }, [_id, markCompletionMessageShown]);

  useEffect(() => {
    if (topics?.length && completedCourseTopicsLoaded) {
      const courseTopicIds = map(topics, '_id') || [];
      const completedIds = intersection(keys(get(completedCourseTopics, _id, {})), courseTopicIds);
      setCompletedTopicIds(completedIds);
      const completedLength = completedIds.length;
      const percent = Math.round(100 * (completedLength / topics.length));
      setPercentCompleted(percent);
      if (percent > 0) {
        setCourseStartCtaTextKey('common:textResumeCourse');
      }

      const topic = find(topics, ({ _id: topicId }) => !includes(completedIds, topicId));
      if (topic) {
        setNextTopic(topic);
      }
    }
  }, [_id, topics, completedCourseTopicsLoaded, completedCourseTopics]);

  useEffect(() => {
    if (
      !courseHasBeenMarkedCompleted &&
      completedCoursesLoaded &&
      !has(completedCourses, _id) &&
      has(completedCourseTopics, _id)
    ) {
      const completedIds = keys(get(completedCourseTopics, _id));
      const allTopicIds = map(topics, '_id');
      if (difference(allTopicIds, completedIds).length === 0) {
        markCourseAsCompleted(_id);
        setCourseHasBeenMarkedCompleted(true);
      }
    }
  }, [
    _id,
    topics,
    completedCoursesLoaded,
    courseHasBeenMarkedCompleted,
    completedCourseTopics,
    completedCourses,
    markCourseAsCompleted
  ]);

  useEffect(() => {
    if (!completedCoursesCalculationsCompleted && completedCoursesLoaded && topics.length) {
      if (has(completedCourses, _id)) {
        setShowNextTopicLink(false);
        if (!get(completedCourses, `['${_id}'].viewedCompletionMessage`, false)) {
          triggerCompletionMessage();
        }
        setCourseHasBeenMarkedCompleted(true);
      }
      // only fire this once after loaded.
      setCompletedCoursesCalculationsCompleted(true);
    }
  }, [
    _id,
    topics,
    completedCoursesLoaded,
    completedCoursesCalculationsCompleted,
    completedCourses,
    triggerCompletionMessage
  ]);

  const { t } = useTranslation();

  const showTopics = (e) => {
    e.preventDefault();
    setShowAllTopics('showAllTopics');
    return false;
  };

  const courseStartCtaText = t(courseStartCtaTextKey);

  const { courseImage, assetParentContentTypeId, assetParentFieldName } = useCourseImage(
    _id,
    thumbnail || image,
    category
  );
  const topicDetails = useTopicDetails(topics);

  return (
    <div data-testid="PageCourse" className={styles.pageCourseWrap} {...sidekick(_id, null, null, 'Page - Course')}>
      <section
        className={cx(styles.heroSection, 'themeBgColor', `theme-${getThemeClass({ _contentTypeId, category })}`)}>
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            {courseImage ? (
              <div className={styles.heroImageCol} data-testid="PageCourse-image">
                <Asset
                  _id={assetParentContentTypeId}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...courseImage}
                  fieldName={assetParentFieldName}
                  imageWrapClassName="imageCircle"
                />
              </div>
            ) : null}

            <div className={styles.heroContentCol}>
              <div className="row no-gutters">
                <div className="col-24">
                  <div className={styles.heroOverview} data-testid="PageCourse-heroOverview">
                    {t('common:formInputLabelOverview')}
                  </div>
                </div>
              </div>

              <div className="row no-gutters">
                <div className={styles.heroNameCol}>
                  <h1 data-testid="PageCourse-name" className={styles.heroName} {...sidekick(_id, 'name')}>
                    {name}
                  </h1>

                  {topics?.length ? (
                    <div className="d-flex flex-wrap flex-xl-nowrap">
                      <div className={styles.heroTopicsWrap} data-testid="PageCourse-topicDetails">
                        {topicDetails}
                      </div>
                      <div className={styles.heroProgressWrap}>
                        <div className={styles.heroProgress} data-testid="PageCourse-progress">
                          <span>{t('common:textCourseProgress')}: </span>
                          <span>
                            {percentCompleted}% {t('common:textComplete')}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className={styles.heroDescriptionCol}>
                  <div className={styles.heroDescriptionWrap} {...sidekick(_id, 'description')}>
                    <p className={styles.heroDescription} data-testid="PageCourse-description">
                      {description}
                    </p>
                  </div>

                  {showNextTopicLink && topics?.length ? (
                    <div className={styles.heroCtaWrap} data-testid="PageCourse-LinkWrap">
                      <TopicLink
                        linkText={courseStartCtaText}
                        topic={nextTopic || topics[0]}
                        courseHref={_href}
                        courseAs={_as}
                        className={styles.heroCta}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.courseProgressWrap}>
          <div className={styles.progress}>
            <div
              data-testid="PageCourseNavigation-progressBar"
              className={styles.progressBar}
              style={{ width: `${percentCompleted}%` }}
              role="progressbar"
              aria-valuenow={percentCompleted}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
      </section>

      <section className={styles.contentSection}>
        <div className={styles.contentContainer}>
          <div className={styles.contentRow}>
            <div className={styles.learningObjectivesCol}>
              <div className={styles.learningObjectivesWrap}>
                <div className={styles.learningObjectivesHeaderWrap}>
                  <h2 className={styles.learningObjectivesHeader} data-testid="PageCourse-learningObjectivesHeader">
                    {t(`common:textLearningObjectives`)}
                  </h2>
                </div>

                <div className={styles.learningObjectivesContentWrap} {...sidekick(_id, 'objectives')}>
                  <p data-testid="PageCourse-learningObjectives">{objectives}</p>
                </div>
              </div>
            </div>

            <div className={`${styles[showAllTopics]} ${styles.tableOfContentsCol}`}>
              <div className={styles.tableOfContentsHeaderWrap}>
                <h2 className={styles.tableOfContentsHeader} data-testid="PageCourse-tableOfContentsHeader">
                  {t(`common:textTableOfContents`)}
                </h2>
              </div>

              <div
                className={styles.tableOfContentsWrap}
                {...sidekick(_id, 'topics')}
                data-testid="PageCourse-tableOfContentsWrap">
                <ul className={styles.tableOfContents} data-testid="PageCourse-tableOfContentsTopics">
                  {topics?.length
                    ? topics.map((topic) => {
                        const elementLinkClassName = includes(completedTopicIds, topic._id)
                          ? `${styles.topicName} ${styles.topicNameActive}`
                          : styles.topicName;
                        return (
                          <li
                            className={styles.topicWrap}
                            key={`${topic._id}-wrap`}
                            data-testid="PageCourse-TopicsListItem">
                            <TopicLink
                              topic={topic}
                              courseHref={_href}
                              courseAs={_as}
                              className={elementLinkClassName}
                            />
                          </li>
                        );
                      })
                    : null}
                </ul>

                {topics?.length > 7 ? (
                  <div className={styles.seeMoreTextLinkWrap} data-testid="PageCourse-SeeMoreLinkWrap">
                    <ElementLink
                      href="#"
                      isModal={false}
                      download={false}
                      trackingId="page_course_see_more"
                      linkText={t(`common:textSeeMore`)}
                      className={styles.seeMoreTextLink}
                      onClick={(e) => showTopics(e)}>
                      {t(`common:textSeeMore`)}
                    </ElementLink>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      {enableCompletionPopup && (
        <CourseFinishedPopup
          _id={_id}
          _contentTypeId={_contentTypeId}
          image={courseCompleteImage}
          headerText={courseCompleteHeaderText}
          bodyText={courseCompleteBody}
          cta={courseCompleteCta}
          showIsCourseFinished={enableCompletionPopup}
          className={`theme-${getThemeClass({ _contentTypeId, category })}`}
        />
      )}
    </div>
  );
}
PageCourse.propTypes = {
  ...PageCoursePropTypes,
  currentCourse: PropTypes.shape({
    courseId: PropTypes.string,
    completed: PropTypes.bool.isRequired,
    completing: PropTypes.bool.isRequired
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  completedTopicsObject: PropTypes.object.isRequired,
  setCourse: PropTypes.func.isRequired
  // isLoggedIn: PropTypes.bool
};

PageCourse.defaultProps = {
  // isLoggedIn: false
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCourse);
