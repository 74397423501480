/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './ModuleFullWidthContainer.module.scss';
import Asset from '../Asset';
import AssetPropTypes from '../Asset/AssetPropTypes';
import ElementLink, { ElementLinkPropTypes } from '../ElementLink';

export const ModuleFullWidthContainerPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  ctaButton: PropTypes.shape(ElementLinkPropTypes),
  image: PropTypes.shape(AssetPropTypes),
  className: PropTypes.string,
  themeClassName: PropTypes.string
};

function ModuleFullWidthContainer({
  _id,
  /* _contentTypeId, */ title,
  body,
  ctaButton,
  image,
  className,
  themeClassName
}) {
  const contentColClass = title || body || ctaButton ? 'contentColFull' : 'contentCol';

  return (
    <section className={cx(className, 'themeBgColor', themeClassName, styles[themeClassName])}>
      <div data-testid="ModuleFullWidthContainer" className={styles.moduleContainerWrap}>
        <div className={styles.moduleContainerRow}>
          {image ? (
            <div className={styles.mediaCol} {...sidekick(_id, 'image', null, 'Image')}>
              <Asset _id={_id} imageWrapClassName={styles.imageWrap} fieldName="image" {...image} />
            </div>
          ) : null}

          {title || body || ctaButton ? (
            <div className={`${styles[contentColClass]} ${styles.textCol}`}>
              {title ? (
                <div className={styles.titleWrap} {...sidekick(_id, 'title', null, 'Title')}>
                  <h2 className={styles.title} data-testid="ContainerTitle">
                    {title}
                  </h2>
                </div>
              ) : null}

              {body ? (
                <div className={styles.bodyWrap} {...sidekick(_id, 'body', null, 'Body')}>
                  <div className={styles.body} data-testid="ContainerBody">
                    {body}
                  </div>
                </div>
              ) : null}

              {ctaButton ? (
                <div
                  data-testid="ContainerCtaButton"
                  className={styles.ctaButtonWrap}
                  {...sidekick(_id, 'ctaButton', null, 'CTA Button')}>
                  <ElementLink {...ctaButton} className={styles.ctaButton} trackingId={`view_all_trainings_${ctaButton.href}`}/>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}
ModuleFullWidthContainer.propTypes = ModuleFullWidthContainerPropTypes;

ModuleFullWidthContainer.defaultProps = {
  title: null,
  body: null,
  ctaButton: null,
  image: null,
  className: '',
  themeClassName: ''
};

export default ModuleFullWidthContainer;
