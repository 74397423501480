/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import ReactMarkdown from 'react-markdown';
import camelCase from 'lodash/camelCase';
import sidekick from '@last-rev/contentful-sidekick-util';
import styles from './VideoCard.module.scss';
import Asset from '../../Asset';
import AssetPropTypes from '../../Asset/AssetPropTypes';
import ElementLink from '../../ElementLink';
import ImageExpand from '../../ImageExpand';

export const VideoCardPropTypes = {
  _id: PropTypes.string,
  slug: PropTypes.string,
  headerText: PropTypes.string,
  summaryText: PropTypes.string,
  image: PropTypes.shape(AssetPropTypes),
  imageExpand: PropTypes.bool,
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  subjects: PropTypes.string,
  tagFilter: PropTypes.func,
  wistiaData: PropTypes.object
};

const VideoCard = ({
  _id,
  headerText,
  summaryText,
  image,
  imageExpand,
  className,
  tags,
  subjects,
  slug,
  tagFilter,
  wistiaData
}) => {
  // NOTE: Contentful change, label only not prop name
  // Tags changed to Subcategories and Subjects changed to Categories
  const tagClasses = tags?.map((category) => `subcategory-${camelCase(category)}`);
  const subjectClass = `category-${camelCase(subjects)}`;

  const isSvg = image && image.url.includes('.svg');

  return (
    <div data-testid={`VideoCard-${_id}`} className={cx(styles.cardWrap, tagClasses, subjectClass, 'videoCard')}>
      <ElementLink href={slug} as={slug} isModal={false} download={false} linkText="" trackingId={`video_card_${slug}`}>
        <div className={cx(styles.card, [className])}>
          <div className={cx(styles.thumbWrap, isSvg && styles.isSvg)}>
            {image ? <Asset {...image} _id={_id} imageWrapClassName={styles.imageWrap} /> : null}
            {imageExpand && image ? <ImageExpand image={image} _id={_id} /> : null}
          </div>

          {tags ? (
            <div {...sidekick(_id, 'tags', null, 'Categories')} data-testid="VideoCard-categories">
              {tags.map((category) => (
                <span
                  key={camelCase(category)}
                  href={`?subcategory=${camelCase(category)}`}
                  as={`?subcategory=${camelCase(category)}`}
                  role="button"
                  tabIndex="-1"
                  onKeyDown={(e) => tagFilter(category, e)}
                  onClick={(e) => tagFilter(category, e)}
                  data-testid={`VideoCard-tag-${category}`}
                  className={styles.category}>
                  {category}
                </span>
              ))}
            </div>
          ) : null}

          {headerText || summaryText || !!wistiaData?.name || !!wistiaData?.description ? (
            <div data-testid="VideoCard-TextWrap" className={styles.cardBody}>
              {headerText || !!wistiaData?.name ? (
                <div className={styles.cardTitleWrap} {...sidekick(_id, 'headerText', null, 'Header Text')}>
                  <h3 data-testid="VideoCard-headerText" className={styles.cardTitle}>
                    {headerText ?? wistiaData?.name}
                  </h3>
                </div>
              ) : null}

              {summaryText || !!wistiaData?.description ? (
                <div className={styles.cardTextWrap} {...sidekick(_id, 'summaryText', null, 'Summary Text')}>
                  <div data-testid="VideoCard-summaryText" className={styles.cardText}>
                    {summaryText ? (
                      <ReactMarkdown>{summaryText}</ReactMarkdown>
                    ) : (
                      // eslint-disable-next-line react/no-danger
                      <div dangerouslySetInnerHTML={{ __html: wistiaData?.description.substring(0, 200) }} />
                    )}
                  </div>
                </div>
              ) : null}

              {slug ? <span className={styles.cardCta}>Watch Now</span> : null}
            </div>
          ) : null}
        </div>
      </ElementLink>
    </div>
  );
};

VideoCard.propTypes = VideoCardPropTypes;

VideoCard.defaultProps = {
  slug: null,
  headerText: null,
  summaryText: null,
  image: null,
  imageExpand: false,
  _id: null,
  className: null,
  tagFilter: null,
  tags: null,
  subjects: null,
  wistiaData: {}
};

export default VideoCard;
