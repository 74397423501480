/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get'; // Removed smartCase with sidekick disabling
import { useComponentLookupContext } from '../../ComponentLookupContext';

export const ContentModulePropTypes = {
  contentTypeId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.object.isRequired,
  wrapSection: PropTypes.bool,
  pageLandingSlug: PropTypes.string
};

function ContentModule({ wrapSection, contentTypeId, fields, pageLandingSlug }) {
  const componentLookup = useComponentLookupContext();

  const Main = componentLookup(contentTypeId);

  useEffect(() => {
    if (fields._contentTypeId === 'moduleRichText') {
      const contentSection = document.querySelector("section.PageCourseTopic_contentSection__3PquI");
      if (contentSection) {
        const aTags = contentSection.querySelectorAll("a:not([data-uxa-log])");
        for (const aTag of aTags) {
          const innerText = aTag.textContent.replace(/\s+/g, '_').toLowerCase();
          aTag.setAttribute("data-uxa-log", "articles_" + innerText);
          aTag.setAttribute("data-uxa-interactions", "click shown"); // replace with your desired value
        }
      }
    }
  }, [fields]);

  // UXA tracking implementation for other ways to help
  if(fields.linkText == 'X' || fields.linkText == 'Contact Support' || fields.linkText == 'Community') {
    const pathname = "other_ways_to_help";

    const linkText = fields.linkText
      .toLowerCase() // convert to lowercase
      .replace(/\s+/g, '_') // replace one or more spaces with _
      .replace(/[^\w]/g, '_'); // replace special characters with _
    fields['trackingId'] = pathname + "_" + linkText;
  }

  if (!Main) {
    // eslint-disable-next-line no-console
    console.log(
      `Did not find mapping for Content Type ${contentTypeId}. Please add an override to the build property in .lastrevrc`
    );
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  if (!wrapSection) return <Main {...fields} />;

  const defaultThemeName = 'default';
  let theme = get(fields, 'theme.theme', defaultThemeName);

  switch (fields._contentTypeId) {
    case 'moduleFullWidthContainer':
      if (get(fields, 'cta.as', '').startsWith('/virtual-training')) theme = 'virtual-training';
      break;
    case 'moduleTopics':
      if (pageLandingSlug !== 'home') theme = 'topics';
      break;
    case 'moduleMultiColumnVideos':
      theme = 'videos';
      break;
    default:
      break;
  }

  const themeClassName = `theme-${theme}`;

  return <Main {...fields} themeClassName={themeClassName} className={`section ${fields._contentTypeId}`} />;
}

ContentModule.propTypes = ContentModulePropTypes;

ContentModule.defaultProps = {
  wrapSection: false,
  pageLandingSlug: ''
};

export default ContentModule;
