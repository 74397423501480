import PropTypes from 'prop-types';
import AssetPropTypes from '../Asset/AssetPropTypes';
import { ElementLinkPropTypes } from '../ElementLink';
import PageCourseTopicPropTypes from '../PageCourseTopic/PageCourseTopicPropTypes';
import CategoriesPropTypes from '../Categories/CategoriesPropTypes';

export default {
  _id: PropTypes.string.isRequired,
  _href: PropTypes.string.isRequired,
  _as: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  category: PropTypes.shape(CategoriesPropTypes).isRequired,
  image: PropTypes.shape(AssetPropTypes).isRequired,
  thumbnail: PropTypes.shape(AssetPropTypes).isRequired,
  description: PropTypes.string.isRequired,
  objectives: PropTypes.string.isRequired,
  expectations: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape(PageCourseTopicPropTypes)).isRequired,
  courseCompleteImage: PropTypes.shape(AssetPropTypes).isRequired,
  courseCompleteHeaderText: PropTypes.string.isRequired,
  courseCompleteBody: PropTypes.string.isRequired,
  courseCompleteCta: PropTypes.shape(ElementLinkPropTypes).isRequired
};
