module.exports={
  "localeDetection": false,
  "locales": [
    "en-US",
    "fr",
    "de",
    "it",
    "ja",
    "pt-BR",
    "es"
  ],
  "defaultLocale": "en-US",
  "loadLocaleFrom": (lang, ns) => import(`./locales/${lang}/${ns}.json`).then((m) => m.default),
  "pages": {
    "*": [
      "common"
    ]
  },
  "logBuild": false
}