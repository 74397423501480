/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import styles from './ModuleFeaturedContent.module.scss';
import ElementWistiaVideo from '../ElementWistiaVideo';
import { ElementVideoPropTypes } from '../ElementVideo';
import PageCourseTopicPropTypes from '../PageCourseTopic/PageCourseTopicPropTypes';
import ElementLink from '../ElementLink/ElementLink';

export const ModuleFeaturedContentPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape(PageCourseTopicPropTypes)),
  video: PropTypes.shape(ElementVideoPropTypes),
  className: PropTypes.string
};

export const TopicCols = ({ topics }) => {
  if (!topics?.length) return null;

  return topics.map((topic) => (
    <div className={styles.topicsCol} key={topic._id}>
      <div className={styles.topicWrap}>
        <ElementLink
          href="/virtual-training"
          isModal={false}
          download={false}
          linkText={topic.name}
          className={styles.topicName}
          data-testid="Breadcrumb-linkOneText">
          {topic.name}
        </ElementLink>
      </div>
    </div>
  ));
};

TopicCols.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.shape(PageCourseTopicPropTypes)).isRequired
};

export const VideoCols = ({ video }) => (
  <>
    <div className={styles.videoTextCol} data-testid="VideoTextWrap">
      {video.title ? (
        <div {...sidekick(video._id, 'title', null, 'Video Title')}>
          <strong data-testid="VideoTitle">{video.title}</strong>
        </div>
      ) : null}

      {video.description ? (
        <div {...sidekick(video._id, 'description', null, 'Video Description')}>
          <p data-testid="VideoDescription">{video.description}</p>
        </div>
      ) : null}
    </div>

    <div className={styles.videoCol} {...sidekick(video._id, 'videoId', null, 'Video')} data-testid="VideoWrap">
      <ElementWistiaVideo {...video} />
    </div>
  </>
);

VideoCols.propTypes = {
  video: PropTypes.shape(ElementVideoPropTypes).isRequired
};

function ModuleFeaturedContent({ _id, title, topics, video, className }) {
  const titleColClass = !topics && video ? 'titleColVideo' : 'titleColTopics';
  return (
    <section
      className={className}
      data-testid="ModuleFeaturedContent"
      {...sidekick(_id, null, null, title || 'Module - Featured Content')}>
      <div className={styles.moduleContainerWrap}>
        <div className={styles.moduleContainerRow}>
          <div className={styles[titleColClass]} data-testid="TitleWrap">
            <div className={styles.sectionTitleWrap} {...sidekick(_id, 'title', null, 'Title')}>
              <h3 className={styles.sectionTitle} data-testid="ModuleFeaturedContent-title">
                {title}
              </h3>
            </div>
          </div>

          {(topics || video) && (
            <>{topics ? <TopicCols id={_id} topics={topics} /> : <VideoCols id={_id} video={video} />}</>
          )}
        </div>
      </div>
    </section>
  );
}

ModuleFeaturedContent.propTypes = ModuleFeaturedContentPropTypes;

ModuleFeaturedContent.defaultProps = {
  topics: null,
  video: null,
  className: ''
};

export default ModuleFeaturedContent;
