const minutesToHours = (minutes = 0) => {
  const time = {};
  const hours = Math.floor(parseInt(minutes, 10) / 60);
  const mins = minutes % 60;

  if (hours) {
    time.hours = hours;
    time.hoursLookupShort = hours > 1 ? 'textHrs' : 'textHr'; // Localization lookup key
    time.hoursLookupLong = hours > 1 ? 'textHours' : 'textHour'; // Localization lookup key
  }

  if (mins) {
    time.minutes = mins;
    time.minutesLookupShort = mins > 1 ? 'textMins' : 'textMin'; // Localization lookup key
    time.minutesLookupLong = mins > 1 ? 'textMinutes' : 'textMinute'; // Localization lookup key
  }

  return time;
};

export default minutesToHours;
