import React, { useState } from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './ModuleTopics.module.scss';
import ElementLink from '../ElementLink';
import PageCourseTopicPropTypes from '../PageCourseTopic/PageCourseTopicPropTypes';

export const ModuleTopicsPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  viewAllLinkText: PropTypes.string,
  topics: PropTypes.arrayOf(PropTypes.shape(PageCourseTopicPropTypes)).isRequired,
  className: PropTypes.string,
  themeClassName: PropTypes.string
};

function ModuleTopics({ _id, _contentTypeId, title, body, viewAllLinkText, topics, className, themeClassName }) {
  const [showAll, setShowAll] = useState('');

  const showAllItems = (e) => {
    e.preventDefault();
    setShowAll('showAll');
    return false;
  };

  const nextRouter = useRouter();
  const asPathName = nextRouter ? nextRouter.asPath : '/';
  const isTopicsLandingPage = asPathName.endsWith('/self-guided-learning');

  return (
    <section
      data-testid="ModuleTopics"
      {...sidekick(_id, null, null, 'Module - Topics')}
      className={cx(
        isTopicsLandingPage ? styles[_contentTypeId] : null,
        className,
        themeClassName,
        styles[themeClassName],
        styles[showAll]
      )}>
      <div className={cx(isTopicsLandingPage ? styles.topicLandingContainer : null, styles.moduleContainerWrap)}>
        <div className={isTopicsLandingPage ? styles.topicLandingInnerContainer : null}>
          <div className={isTopicsLandingPage ? styles.moduleContainerRowLanding : styles.moduleContainerRow}>
            <div className={isTopicsLandingPage ? styles.sectionRow : styles.sectionCol}>
              <div className={styles.sectionTitleWrap} {...sidekick(_id, 'title')}>
                <h2
                  className={isTopicsLandingPage ? styles.sectionTitleLanding : styles.sectionTitle}
                  data-testid="ModuleTitle">
                  {title}
                </h2>
              </div>

              {body ? (
                <div className={styles.sectionBodyWrap} {...sidekick(_id, 'body')}>
                  <p className={styles.sectionBody} data-testid="ModuleBody">
                    {body}
                  </p>
                </div>
              ) : null}

              {isTopicsLandingPage && viewAllLinkText && topics?.length ? (
                <div className={styles.viewMoreTextLinkWrapLanding} data-testid="ModuleViewAllLinkWrap">
                  <ElementLink
                    href="#"
                    isModal={false}
                    download={false}
                    onClick={(e) => showAllItems(e)}
                    linkText={viewAllLinkText}
                    className={styles.viewMoreTextLink}>
                    {viewAllLinkText}
                  </ElementLink>
                </div>
              ) : null}

              {!isTopicsLandingPage && viewAllLinkText && topics?.length ? (
                <div className={styles.viewMoreTextLinkWrap} data-testid="ModuleViewAllLinkWrap">
                  <ElementLink
                    href="/[slug]"
                    as="/self-guided-learning"
                    isModal={false}
                    download={false}
                    linkText={viewAllLinkText}
                    trackingId="view_all_modules_/[slug]"
                    className={styles.viewMoreTextLink}>
                    {viewAllLinkText}
                  </ElementLink>
                </div>
              ) : null}
            </div>

            <div className={isTopicsLandingPage ? styles.topicListRow : styles.topicListCol}>
              <div className={styles.topicList} data-testid="ModuleTopicList">
                {topics?.length
                  ? topics.map((topic) => (
                      <div className={cx(styles.topicWrap, 'topicWrap')} key={topic._id} data-testid="ModuleTopicItem">
                        <ElementLink
                          as={topic._as}
                          href={topic._href}
                          isModal={false}
                          download={false}
                          linkText={topic.name}
                          trackingId={`course_module_${topic._href}`}
                          className={styles.topicName}>
                          {topic.name}
                        </ElementLink>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
ModuleTopics.propTypes = ModuleTopicsPropTypes;

ModuleTopics.defaultProps = {
  body: null,
  viewAllLinkText: 'View More Topics',
  className: '',
  themeClassName: ''
};

export default ModuleTopics;
