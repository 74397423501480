import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import cx from 'clsx';
import styles from './ModuleTextList.module.scss';
import RichTextParser from '../RichTextParser';
import RichTextParserPropTypes from '../RichTextParser/RichTextParserPropTypes';

export const ModuleTextListPropTypes = {
  listStyle: PropTypes.string.isRequired,
  listContent: PropTypes.shape(RichTextParserPropTypes).isRequired
};

function ModuleTextList({ listStyle, listContent }) {
  return (
    <div className={cx(styles.module, styles[camelCase(listStyle)])} data-testid="ModuleTextList">
      <RichTextParser document={listContent} />
    </div>
  );
}

ModuleTextList.propTypes = ModuleTextListPropTypes;

export default ModuleTextList;
