import reject from 'lodash/reject';
import {
  ERROR,
  SELECT_LANGUAGE,
  SELECT_DATE,
  SELECT_TIME,
  COMPLETING_EVENT_REGISTRATION,
  COMPLETED_EVENT_REGISTRATION,
  CANCELING_EVENT_REGISTRATION,
  CANCELED_EVENT_REGISTRATION,
  SET_CURRENT_EVENT_REGISTRATION,
  LOADING_EVENT_REGISTRATIONS,
  LOADED_EVENT_REGISTRATIONS
} from './webinars.types';

export const initialState = {
  eventRegistrations: [],
  loadingEventRegistrations: false,
  current: {
    selectedWebinarId: null,
    selectedOccurrenceId: null,
    selectedLanguage: null,
    selectedDate: null,
    selectedTime: null,
    occurrences: [],
    availableDates: [],
    availableTimes: [],
    reschedulingOccurrenceId: null,
    reschedulingWebinarId: null,
    completingRegistration: false,
    completedRegistration: false,
    cancelingRegistration: false,
    canceledRegistration: false
  },
  error: null,
  webinarFull: null
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ERROR: {
      const { error, webinarFull } = payload;
      return {
        ...state,
        error,
        webinarFull
      };
    }

    case SET_CURRENT_EVENT_REGISTRATION: {
      const { reschedulingOccurrenceId = null, reschedulingWebinarId = null } = payload;

      return {
        ...state,
        error: null,
        webinarFull: null,
        current: {
          ...initialState.current,
          reschedulingOccurrenceId,
          reschedulingWebinarId
        }
      };
    }
    case SELECT_LANGUAGE: {
      const { selectedLanguage, selectedWebinarId, occurrences, availableDates } = payload;
      return {
        ...state,
        error: null,
        webinarFull: null,
        current: {
          ...state.current,
          selectedLanguage,
          selectedWebinarId,
          occurrences,
          availableDates,
          selectedOccurrenceId: null,
          selectedDate: null,
          selectedTime: null,
          availableTimes: [],
          completingRegistration: false,
          completedRegistration: false,
          cancelingRegistration: false,
          canceledRegistration: false
        }
      };
    }

    case SELECT_DATE: {
      const { selectedDate, availableTimes } = payload;
      return {
        ...state,
        error: null,
        webinarFull: null,
        current: {
          ...state.current,
          selectedDate,
          availableTimes,
          selectedTime: null,
          selectedOccurrenceId: null,
          completingRegistration: false,
          completedRegistration: false,
          cancelingRegistration: false,
          canceledRegistration: false
        }
      };
    }

    case SELECT_TIME: {
      const { selectedTime, selectedOccurrenceId } = payload;
      return {
        ...state,
        error: null,
        webinarFull: null,
        current: {
          ...state.current,
          selectedTime,
          selectedOccurrenceId,
          completingRegistration: false,
          completedRegistration: false,
          cancelingRegistration: false,
          canceledRegistration: false
        }
      };
    }

    case COMPLETING_EVENT_REGISTRATION: {
      return {
        ...state,
        error: null,
        webinarFull: null,
        current: {
          ...state.current,
          completingRegistration: true,
          completedRegistration: false,
          cancelingRegistration: false,
          canceledRegistration: false
        }
      };
    }

    case COMPLETED_EVENT_REGISTRATION: {
      return {
        ...state,
        error: null,
        webinarFull: null,
        current: {
          ...state.current,
          completingRegistration: false,
          completedRegistration: true,
          cancelingRegistration: false,
          canceledRegistration: false
        }
      };
    }

    case CANCELING_EVENT_REGISTRATION: {
      return {
        ...state,
        error: null,
        webinarFull: null,
        current: {
          ...state.current,
          completingRegistration: false,
          completedRegistration: false,
          cancelingRegistration: true,
          canceledRegistration: false
        }
      };
    }

    case CANCELED_EVENT_REGISTRATION: {
      const { webinarId, occurrenceId } = payload;
      return {
        ...state,
        error: null,
        webinarFull: null,
        eventRegistrations: reject(
          state.eventRegistrations,
          (webinar) => webinarId === webinar.webinarId && occurrenceId === webinar.occurrenceId
        ),
        current: {
          ...state.current,
          completingRegistration: false,
          completedRegistration: false,
          cancelingRegistration: false,
          canceledRegistration: true
        }
      };
    }

    case LOADING_EVENT_REGISTRATIONS: {
      return {
        ...state,
        error: null,
        webinarFull: null,
        loadingEventRegistrations: true
      };
    }

    case LOADED_EVENT_REGISTRATIONS: {
      const { eventRegistrations } = payload;
      return {
        ...state,
        error: null,
        webinarFull: null,
        eventRegistrations,
        loadingEventRegistrations: false
      };
    }

    default:
      return state;
  }
}
