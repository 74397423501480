import React from 'react';
import PropTypes from 'prop-types';
import WistiaPlayer from 'react-player/wistia';
import YouTubePlayer from 'react-player/youtube';
import styles from './ElementWistiaVideo.module.scss';

export const ElementWistiaVideoPropTypes = {
  videoId: PropTypes.string.isRequired,
  isYouTube: PropTypes.bool,
  playsInline: PropTypes.bool
};

function ElementWistiaVideo({ videoId, isYouTube, playsInline }) {
  const options = {
    wistia: {
      videoFoam: true,
      popover: playsInline,
      controls: true
    },
    youtube: {
      playerVars: {
        playsinline: playsInline
      }
    }
  };

  const popOverOptions = [
    `popover=${playsInline}`,
    'popoverOverlayColor=#000000',
    'popoverOverlayOpacity=1.0',
    'popoverAnimation=fade',
    'popoverPreventScroll=true',
    'popoverBoxShadow=false'
  ];

  const url = isYouTube
    ? `https://www.youtube.com/watch?v=${videoId}`
    : `//fast.wistia.com/medias/${videoId} ${popOverOptions.join(' ')}`;

  const ReactPlayer = isYouTube ? YouTubePlayer : WistiaPlayer;

  return (
    <div className={styles.iframeWrap} data-testid="ElementWistiaVideo">
      <ReactPlayer url={url} config={options} className={`${styles.wistiaEmbed}`} controls />
    </div>
  );
}

ElementWistiaVideo.propTypes = ElementWistiaVideoPropTypes;

ElementWistiaVideo.defaultProps = {
  isYouTube: false,
  playsInline: false
};

export default ElementWistiaVideo;
