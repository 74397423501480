import PropTypes from 'prop-types';
import AssetPropTypes from '../Asset/AssetPropTypes';
import RichTextParserPropTypes from '../RichTextParser/RichTextParserPropTypes';
import CategoriesPropTypes from '../Categories/CategoriesPropTypes';

const PageLiveCoursePropTypes = {
  _id: PropTypes.string.isRequired,
  _href: PropTypes.string.isRequired,
  _as: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  category: PropTypes.shape(CategoriesPropTypes).isRequired,
  image: PropTypes.shape(AssetPropTypes).isRequired,
  thumbnail: PropTypes.shape(AssetPropTypes).isRequired,
  description: PropTypes.string.isRequired,
  courseHighlights: PropTypes.arrayOf(PropTypes.string),
  duration: PropTypes.number.isRequired,
  // breadcrumbText: PropTypes.string,
  languageZoomIDs: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  registrationConfirmationHeader: PropTypes.string.isRequired,
  registrationConfirmationCopy: PropTypes.shape(RichTextParserPropTypes).isRequired,
  loginPromptHeader: PropTypes.string,
  loginPromptCopy: PropTypes.shape(RichTextParserPropTypes),
  jsonLdSchema: PropTypes.object,
  completedRegistration: PropTypes.bool,
  completingRegistration: PropTypes.bool,
  selectedOccurrenceId: PropTypes.string,
  eventRegistrations: PropTypes.arrayOf(
    PropTypes.shape({
      webinarId: PropTypes.number.isRequired,
      occurrenceId: PropTypes.string.isRequired
    })
  ).isRequired,
  loggedIn: PropTypes.bool,
  error: PropTypes.string,
  webinarFull: PropTypes.bool,
  setCurrent: PropTypes.func,
  submitRegistration: PropTypes.func
};

export default PageLiveCoursePropTypes;
