import PropTypes from 'prop-types';

const AssetPropTypes = {
  _id: PropTypes.string,
  filename: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  size: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  imageWrapClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  isInlineSvg: PropTypes.bool,
  preload: PropTypes.bool,
  bypassOptimization: PropTypes.bool
};

export default AssetPropTypes;
