/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import ReactMarkdown from 'react-markdown';
import sidekick from '@last-rev/contentful-sidekick-util';
import styles from './BasicCard.module.scss';
import Asset from '../../Asset';
import AssetPropTypes from '../../Asset/AssetPropTypes';
import ImageExpand from '../../ImageExpand';
import RichTextParserPropTypes from '../../RichTextParser/RichTextParserPropTypes';
import RichTextParser from '../../RichTextParser/RichTextParser';

export const BasicCardPropTypes = {
  _id: PropTypes.string,
  headerText: PropTypes.string,
  summaryText: PropTypes.string,
  summaryRichText: PropTypes.shape(RichTextParserPropTypes),
  image: PropTypes.shape(AssetPropTypes),
  imageExpand: PropTypes.bool,
  className: PropTypes.string
};

function BasicCard({ _id, headerText, summaryText, image, imageExpand, summaryRichText, className }) {
  const fieldLabel = summaryRichText ? 'summaryRichText' : 'summaryText';
  const displayLabel = summaryRichText ? 'Summary Rich Text' : 'Summary Text';

  return (
    <div data-testid="BasicCard" className={styles.cardWrap}>
      <div className={cx(styles.card, [className])}>
        <div style={{ position: 'relative' }}>
          {image ? <Asset {...image} _id={_id} imageWrapClassName={styles.imageWrap} /> : null}
          {imageExpand && image ? <ImageExpand image={image} _id={_id} /> : null}
        </div>

        {headerText || summaryRichText || summaryText ? (
          <div data-testid="BasicCard-TextWrap" className={styles.cardBody}>
            {headerText ? (
              <div className={styles.cardTitleWrap} {...sidekick(_id, 'headerText', null, 'Header Text')}>
                <h3 data-testid="BasicCard-headerText" className={styles.cardTitle}>
                  {headerText}
                </h3>
              </div>
            ) : null}

            {summaryRichText || summaryText ? (
              <div className={styles.cardTextWrap} {...sidekick(_id, fieldLabel, null, displayLabel)}>
                <div data-testid={`BasicCard-${fieldLabel}`} className={styles.cardText}>
                  {summaryRichText ? (
                    <RichTextParser document={summaryRichText} />
                  ) : (
                    <ReactMarkdown>{summaryText}</ReactMarkdown>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
BasicCard.propTypes = BasicCardPropTypes;

BasicCard.defaultProps = {
  headerText: null,
  summaryText: null,
  summaryRichText: null,
  image: null,
  imageExpand: false,
  _id: null,
  className: null
};

export default BasicCard;
