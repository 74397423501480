export default {
  'title': {
    name: 'title',
    value: 'Dropbox'
  },
  'description': {
    name: 'description',
    value: 'Lorem Ipsum'
  },
  'keywords': {
    name: 'keywords',
    value: 'lms, test, this'
  },
  'og:image': {
    name: 'og:image',
    value: {
      url: 'https://images.ctfassets.net/zh1yxk5yn2r7/33cKbUcUgjCOB3aPxkIMGq/14cdd574f957e960f0972f73a80a892e/hoop.png',
      id: '33cKbUcUgjCOB3aPxkIMGq',
      title: 'hoop'
    }
  }
};
