
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import Amplify from 'aws-amplify';
import '../styles/global.scss';
import reducer from '../redux';
import { ComponentLookupContextProvider } from '../ComponentLookupContext';
import { CourseTopicContextProvider } from '../CourseTopicContext';

import mapping from '../utils/mappings';

import pathsData from '../buildArtifacts/paths';

const { pageCourseTopicNested: paths } = pathsData;

Amplify.configure({
  Auth: {
    identityPoolId: process.env.IDENTITY_POOL_ID,
    region: process.env.COGNITO_REGION,
    userPoolId: process.USER_POOL_ID,
    userPoolWebClientId: process.USER_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: process.env.ENDPOINT_NAME,
        endpoint: process.env.ENDPOINT_URL,
        region: process.env.ENDPOINT_REGION
      }
    ]
  },
  aws_appsync_graphqlEndpoint: process.env.APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.APPSYNC_REGION,
  aws_appsync_authenticationType: process.env.APPSYNC_AUTHENTICATION_TYPE
});

function MyApp({ Component, pageProps, err }) {
  return (
    <ComponentLookupContextProvider componentLookup={(contentType) => mapping[contentType]}>
      <CourseTopicContextProvider value={paths}>
        <Component {...pageProps} err={err} />
      </CourseTopicContextProvider>
    </ComponentLookupContextProvider>
  );
}

const __Page_Next_Translate__ = reducer.withRedux(MyApp);


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  