import React from 'react';
import PropTypes from 'prop-types';
import RichTextParser from '../RichTextParser';
import RichTextParserPropTypes from '../RichTextParser/RichTextParserPropTypes';

export const ModuleRichTextPropTypes = {
  bodyText: PropTypes.shape(RichTextParserPropTypes).isRequired
};

function ModuleRichText({ bodyText }) {
  return <RichTextParser document={bodyText} />;
}
ModuleRichText.propTypes = ModuleRichTextPropTypes;

export default ModuleRichText;
