const prefix = 'learn.dropbox.com/courses';

export const ADD_COMPLETED_COURSE = `${prefix}/ADD_COMPLETED_COURSE`;
export const LOADING_COMPLETED_COURSES = `${prefix}/LOADING_COMPLETED_COURSE`;
export const LOAD_COMPLETED_COURSES = `${prefix}/LOAD_COMPLETED_COURSE`;
export const ADD_COMPLETED_COURSE_TOPIC = `${prefix}/ADD_COMPLETED_COURSE_TOPIC`;
export const LOADING_COMPLETED_COURSE_TOPICS = `${prefix}/LOADING_COMPLETED_COURSE_TOPICS`;
export const LOAD_COMPLETED_COURSE_TOPICS = `${prefix}/LOAD_COMPLETED_COURSE_TOPICS`;
export const LOADING_COMPLETED_TOPICS = `${prefix}/LOADING_COMPLETED_TOPICS`;
export const LOAD_COMPLETED_TOPICS = `${prefix}/LOAD_COMPLETED_TOPICS`;
