import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import styles from './ModuleCarouselSlide.module.scss';
import Asset from '../../Asset';
import AssetPropTypes from '../../Asset/AssetPropTypes';
import ElementLink, { ElementLinkPropTypes } from '../../ElementLink/ElementLink';
import RichTextParserPropTypes from '../../RichTextParser/RichTextParserPropTypes';
import RichTextParser from '../../RichTextParser/RichTextParser';
import ImageExpand from '../../ImageExpand';

export const ModuleCarouselSlidePropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  summaryText: PropTypes.string,
  summaryRichText: PropTypes.shape(RichTextParserPropTypes),
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.shape(AssetPropTypes),
  // eslint-disable-next-line react/forbid-prop-types
  cta: PropTypes.shape(ElementLinkPropTypes),
  imageExpand: PropTypes.bool
};

function ModuleCarouselSlide({
  _id,
  /* _contentTypeId, */ image,
  headerText,
  summaryText,
  cta,
  summaryRichText,
  imageExpand
}) {
  const fieldLabel = summaryRichText ? 'summaryRichText' : 'summaryText';
  const displayLabel = summaryRichText ? 'Summary Rich Text' : 'Summary Text';

  return (
    <div
      data-testid="ModuleCarouselSlide"
      className={styles.moduleCarouselSlide}
      {...sidekick(_id, null, null, 'Module - Carousel Slide')}>
      {image ? (
        <div className={styles.imageOuterWrap}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Asset {...image} imageClassName="mx-auto img-fluid" />
          {imageExpand && image ? <ImageExpand image={image} _id={_id} /> : null}
        </div>
      ) : null}

      {headerText || summaryRichText || summaryText ? (
        <div className={styles.slideContentWrap} data-testid="ModuleCarouselSlide-TextWrap">
          {headerText ? (
            <div className={styles.slideHeaderTextWrap}>
              <h3
                className={styles.slideHeaderText}
                data-testid="ModuleCarouselSlide-headerText"
                {...sidekick(_id, 'headerText', null, 'Header Text')}>
                {headerText}
              </h3>
            </div>
          ) : null}

          {summaryRichText || summaryText ? (
            <div className={styles.slideSummaryTextWrap}>
              <div
                className={styles.slideSummaryText}
                data-testid={`ModuleCarouselSlide-${fieldLabel}`}
                {...sidekick(_id, fieldLabel, null, displayLabel)}>
                {summaryRichText ? (
                  <RichTextParser document={summaryRichText} />
                ) : (
                  <ReactMarkdown>{summaryText}</ReactMarkdown>
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {cta && cta.linkText ? (
        <div className={styles.slideSummaryTextWrap} {...sidekick(_id, 'cta', null, 'CTA')}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ElementLink {...cta} />
        </div>
      ) : null}
    </div>
  );
}

ModuleCarouselSlide.propTypes = ModuleCarouselSlidePropTypes;

ModuleCarouselSlide.defaultProps = {
  summaryText: null,
  summaryRichText: null,
  image: null,
  cta: null,
  imageExpand: false
};

export default ModuleCarouselSlide;
