/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './CourseFinishedPopup.module.scss';
import Asset from '../Asset';
import AssetPropTypes from '../Asset/AssetPropTypes';
import ElementLink, { ElementLinkPropTypes } from '../ElementLink';

export const CourseFinishedPopupPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  image: PropTypes.shape(AssetPropTypes).isRequired,
  headerText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  cta: PropTypes.shape(ElementLinkPropTypes).isRequired,
  showIsCourseFinished: PropTypes.bool,
  className: PropTypes.string
};

const checkModuleClassNameState = (canShowIsCourseFinished) => {
  const classNamePopup = canShowIsCourseFinished ? 'pageCourseFinishedShow' : 'pageCourseFinishedShow';
  return classNamePopup;
};

function CourseFinishedPopup({
  _id,
  /* _contentTypeId, */
  showIsCourseFinished,
  headerText,
  image,
  bodyText,
  cta,
  className
}) {
  const [moduleClassName, setModuleClassName] = useState(checkModuleClassNameState(showIsCourseFinished));

  const hidePopup = () => {
    setModuleClassName('pageCourseFinishedHide');
  };

  return (
    <div data-testid="CourseFinishedPopup" className={cx(styles.pageCourseFinished, styles[moduleClassName])}>
      <div className={cx(className, styles.containerWrap)}>
        <div
          data-testid="CourseFinishedPopup-ImageWrap"
          className={styles.imageOuterWrap}
          {...sidekick(_id, 'courseCompleteImage')}>
          <Asset {...image} />
        </div>

        <div
          className={styles.headerTextWrap}
          {...sidekick(_id, 'courseCompleteHeaderText', null, 'Course Complete Header Text')}>
          <div data-testid="CourseFinishedPopup-headerText" className={styles.headerText}>
            {headerText}
          </div>
        </div>

        <div className={styles.bodyTextWrap} {...sidekick(_id, 'courseCompleteBody', null, 'Course Complete Body')}>
          <p data-testid="CourseFinishedPopup-bodyText" className={styles.bodyText}>
            {bodyText}
          </p>
        </div>

        {cta ? (
          <div
            data-testid="CourseFinishedPopup-CtaWrap"
            className={styles.ctaWrap}
            {...sidekick(_id, 'courseCompleteCta', null, 'Course Complete CTA')}>
            <ElementLink
              href={cta.href}
              as={cta.as}
              trackingId={cta.trackingId}
              style={cta.style}
              className={styles.cta}
              linkText={cta.linkText}
              isModal={false}
              download={false}>
              {cta.linkText}
            </ElementLink>
          </div>
        ) : null}
      </div>

      <button type="button" className={cx(styles.closeButton)} aria-label="Close" onClick={() => hidePopup()}>
        <span aria-hidden="true">
          <Asset url="/images/assets/icon-close-x.svg" title="Close" isInlineSvg />
        </span>
        <span className="d-none">Close</span>
      </button>
    </div>
  );
}

CourseFinishedPopup.propTypes = CourseFinishedPopupPropTypes;

CourseFinishedPopup.defaultProps = {
  showIsCourseFinished: false,
  className: null
};

export default CourseFinishedPopup;
