/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
// import merge from 'lodash/merge';
// import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import useTranslation from 'next-translate/useTranslation';
import Head from '../Head';
import GlobalHeader from '../GlobalHeader';
import GlobalFooter from '../GlobalFooter';
import AlertBar from '../AlertBar';
// import tidySeo from '../../utils/tidySeo';
// import getSeoPageTitle from '../../utils/getSeoPageTitle';
import pithos from '../../utils/pithos';

export const LayoutPropTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  seo: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  settingsGlobal: PropTypes.object,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  showOrgSchema: PropTypes.bool,
  pageTitle: PropTypes.string,
  canonicalUrl: PropTypes.string
};

function Layout({ seo, children, settingsGlobal, showHeader, showFooter, pageTitle, showOrgSchema, canonicalUrl }) {
  const {
    _id,
    _contentTypeId,
    topNavigationLogo,
    mainNavigation,
    footerNavigation,
    secondaryNavigation,
    alertBarBody,
    alertBarEnabled,
    alertBarCta
  } = settingsGlobal;

  const [headerVisibityClass, setHeaderVisibityClass] = useState('header-visible');
  const { lang } = useTranslation();
  useEffect(() => {
    pithos();
  }, [lang]);

  const onChange = (inView) => {
    if (inView) setHeaderVisibityClass('header-visible');
    else setHeaderVisibityClass('header-hidden');
  };

  // Removed for SUPPORT-1617
  // const globalSeo = tidySeo(get(settingsGlobal, 'seo', {}));
  // const globalPageTitle = getSeoPageTitle(globalSeo);
  const titleTag = pageTitle; // [pageTitle, globalPageTitle].filter(Boolean).join(' | ');

  // delete globalSeo.title;
  // const mergedSeo = merge(globalSeo, seo);
  const mergedSeo = seo;
  return (
    <>
      <Head seo={mergedSeo} title={titleTag} canonicalUrl={canonicalUrl} />
      <a className="sr-only sr-only-focusable" href="#maincontent">
        Skip to main content
      </a>
      <div className="wrap" role="document">
        <InView onChange={(inView) => onChange(inView)}>
          {alertBarEnabled ? (
            <AlertBar _id={_id} _contentTypeId={_contentTypeId} alertBarBody={alertBarBody} alertBarCta={alertBarCta} />
          ) : null}

          {showHeader ? (
            <GlobalHeader
              _id={_id}
              _contentTypeId={_contentTypeId}
              topNavigationLogo={topNavigationLogo}
              mainNavigation={mainNavigation}
              secondaryNavigation={secondaryNavigation}
            />
          ) : null}
        </InView>

        <div className={`main-wrap ${headerVisibityClass}`}>
          <main id="maincontent">{children}</main>
        </div>

        {showOrgSchema && settingsGlobal.seoOrganizationsSchema && !isEmpty(settingsGlobal.seoOrganizationsSchema) && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(settingsGlobal.seoOrganizationsSchema) }}
          />
        )}

        {showFooter ? <GlobalFooter _id={_id} _contentTypeId={_contentTypeId} columns={footerNavigation} /> : null}
      </div>
    </>
  );
}

Layout.propTypes = LayoutPropTypes;

Layout.defaultProps = {
  showHeader: true,
  settingsGlobal: null,
  seo: {},
  showFooter: false,
  pageTitle: '',
  showOrgSchema: false,
  canonicalUrl: null
};

export default Layout;
