import React from 'react';
import PropTypes from 'prop-types';
import PageCourseTopicPropTypes from '../PageCourseTopic/PageCourseTopicPropTypes';
import ElementLink from '../ElementLink';

export const TopicLinkPropTypes = {
  topic: PropTypes.shape(PageCourseTopicPropTypes).isRequired,
  courseHref: PropTypes.string.isRequired,
  courseAs: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

function TopicLink({ topic, courseHref, courseAs, linkText, className, children }) {
  const href = `${courseHref}/[topicslug]`;
  const as = `${courseAs}/${topic.slug}`;
  const elementLinkText = linkText || topic.name;
  const childContent = children || elementLinkText;

  return (
    <ElementLink href={href} as={as} isModal={false} download={false} linkText={elementLinkText} className={className} trackingId={`course_module_${courseHref}`}>
      {childContent}
    </ElementLink>
  );
}
TopicLink.propTypes = TopicLinkPropTypes;

TopicLink.defaultProps = {
  linkText: null,
  className: null,
  children: null
};

export default TopicLink;
