import PropTypes from 'prop-types';

export default {
  _id: PropTypes.string.isRequired,
  _href: PropTypes.string.isRequired,
  _as: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  eyebrow: PropTypes.string,
  publishDate: PropTypes.string,
  content: PropTypes.object,
  readingTime: PropTypes.number.isRequired,
  courseId: PropTypes.string,
  courseTopics: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired
    })
  ),
  jsonLdSchema: PropTypes.object
};
