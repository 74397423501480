import get from 'lodash/get';

const getThemeClass = (componentData, _contentTypeId) => {
  const defaultThemeName = 'default';
  let theme = get(componentData, 'theme', get(componentData, 'category.theme', defaultThemeName));
  const contentTypeId = _contentTypeId || componentData._contentTypeId;

  const ctaUrl = get(componentData, 'cta.as', '');

  if (theme === defaultThemeName) {
    switch (contentTypeId) {
      case 'moduleFullWidthContainer':
        if (ctaUrl.startsWith('/virtual-training')) theme = 'virtual-training';
        else if (ctaUrl.startsWith('/self-guided-learning')) theme = 'self-guided-learning';
        break;
      case 'moduleTopics':
        theme = 'topics module-topics';
        break;
      case 'moduleMultiColumnVideos':
        theme = 'videos module-videos';
        break;
      default:
        theme = get(componentData, 'category.theme.theme', defaultThemeName);
        break;
    }
  }

  return theme;
};

export default getThemeClass;
