/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from 'react';
import has from 'lodash/has';
import get from 'lodash/get';

const useCourseImage = (contentTypeId, image, category) => {
  const imageURL = useMemo(() => image && get(image, 'url'));
  const categoryImageURL = useMemo(() => category && get(category, 'image.url'));

  return useMemo(() => {
    if (image && image.url) {
      return {
        courseImage: image,
        assetParentContentId: contentTypeId,
        assetParentFieldName: 'image'
      };
    }

    if (category && has(category, 'image.url')) {
      return {
        courseImage: category.image,
        assetParentContentId: category._id,
        assetParentFieldName: 'image'
      };
    }

    return {};
  }, [contentTypeId, imageURL, categoryImageURL]);
};

export default useCourseImage;
