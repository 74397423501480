import React, { useState, useEffect } from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './AlertBar.module.scss';
import ElementLink, { ElementLinkPropTypes } from '../ElementLink';
import Asset from '../Asset';

export const AlertBarPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  alertBarBody: PropTypes.string,
  alertBarCta: PropTypes.shape(ElementLinkPropTypes),
  alertBarEnabled: PropTypes.bool
};

const COOKIE_NAME = 'dropbox-alert-bar-shown';

function AlertBar({ _id, _contentTypeId, alertBarBody, alertBarCta, alertBarEnabled }) {
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const [showAlertBar, setShowAlertBar] = useState(false);

  const cookieVal = cookies[COOKIE_NAME];

  useEffect(() => {
    if (!cookieVal) {
      setShowAlertBar(true);
    }
  }, [cookieVal]);

  if (!alertBarEnabled || (!alertBarBody && !alertBarCta)) return null;

  const hideAlertBar = (e) => {
    e.preventDefault();
    setCookie(COOKIE_NAME, true);
    setShowAlertBar(false);
    return false;
  };

  const alertBarHideClass = showAlertBar ? '' : 'alertBarWrapHidden';

  return (
    <div
      data-testid="AlertBar"
      className={cx(styles[alertBarHideClass], styles.alertBarWrap)}
      {...sidekick(_id, 'alertBarBody', _contentTypeId, 'Alert Bar')}>
      <p className={styles.alertBarBody}>
        {alertBarBody ? <span data-testid="AlertBar-body">{alertBarBody}</span> : null}

        {alertBarCta ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ElementLink {...alertBarCta} className={styles.alertBarCta} />
        ) : null}
      </p>

      <button
        data-testid="AlertBar-CloseButton"
        data-uxa-log="AlertBar-CloseButton"
        type="button"
        className={cx(styles.closeButton)}
        aria-label="Close"
        onClick={(e) => hideAlertBar(e)}>
        <span aria-hidden="true">
          <Asset url="/images/assets/icon-close-x.svg" title="Close" isInlineSvg />
        </span>
        <span className="d-none">Close</span>
      </button>
    </div>
  );
}
AlertBar.propTypes = AlertBarPropTypes;

AlertBar.defaultProps = {
  alertBarBody: null,
  alertBarCta: null,
  alertBarEnabled: true
};

export default AlertBar;
