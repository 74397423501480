import React, { useState } from "react";
import styles from "./ModuleAccordion.module.scss";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { useRouter } from "next/router";

function ModuleAccordian( props ) {
    const {accordianItems}=props
   
    const router = useRouter();
    const [openIndex, setOpenIndex] = useState(null);
    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className={styles.accordian}>
                <hr className={styles.horozontalLine} />
                {accordianItems && accordianItems.length>0 ?
                 accordianItems.map((ele, index) => {
                    return (
                        <div key={index}>
                            <div className={styles.wrapcontainer} onClick={() => handleToggle(index)}>
                                <h5 className={styles.headertext}>{ele.headerText}</h5>
                                <button className={router?.query?.contentType==="pageVideo"? styles.newarrowbutton : styles.arrowbutton}>
                                    {openIndex === index ? <SlArrowUp /> : <SlArrowDown />}
                                </button>
                            </div>

                            {openIndex === index && (
                                <p className={styles.summarytext}>{ele.summaryText}</p>
                            )}
                            <hr />
                        </div>
                    );
                }):""}
        </div>
    );
}

export default ModuleAccordian;
