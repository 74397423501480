/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import ReactMarkdown from 'react-markdown';
import sidekick from '@last-rev/contentful-sidekick-util';
import styles from './StandardCard.module.scss';
import Asset from '../../Asset';
import AssetPropTypes from '../../Asset/AssetPropTypes';
import ImageExpand from '../../ImageExpand';
import ElementLink, { ElementLinkPropTypes } from '../../ElementLink/ElementLink';
import RichTextParserPropTypes from '../../RichTextParser/RichTextParserPropTypes';
import RichTextParser from '../../RichTextParser/RichTextParser';

export const StandardCardPropTypes = {
  _id: PropTypes.string,
  headerText: PropTypes.string,
  summaryText: PropTypes.string,
  summaryRichText: PropTypes.shape(RichTextParserPropTypes),
  image: PropTypes.shape(AssetPropTypes),
  imageExpand: PropTypes.bool,
  cta: PropTypes.shape(ElementLinkPropTypes),
  className: PropTypes.string
};

const CardContent = ({ _id, headerText, summaryText, image, imageExpand, cta, summaryRichText, className }) => {
  const fieldLabel = summaryRichText ? 'summaryRichText' : 'summaryText';
  const displayLabel = summaryRichText ? 'Summary Rich Text' : 'Summary Text';

  return (
    <div className={cx(styles.card, [className])}>
      <div style={{ position: 'relative' }}>
        {image ? <Asset _id={_id} imageWrapClassName={styles.imageWrap} {...image} /> : null}
        {imageExpand && image ? <ImageExpand image={image} _id={_id} /> : null}
      </div>

      {headerText || summaryRichText || summaryText ? (
        <div data-testid="StandardCard-TextWrap" className={styles.cardBody}>
          {headerText ? (
            <div className={styles.cardTitleWrap} {...sidekick(_id, 'headerText', null, 'Header Text')}>
              <h3 data-testid="StandardCard-headerText" className={styles.cardTitle}>
                {headerText}
              </h3>
            </div>
          ) : null}

          {summaryRichText || summaryText ? (
            <div className={styles.cardTextWrap} {...sidekick(_id, fieldLabel, null, displayLabel)}>
              <div data-testid={`StandardCard-${fieldLabel}`} className={styles.cardText}>
                {summaryRichText ? (
                  <RichTextParser document={summaryRichText} />
                ) : (
                  <ReactMarkdown>{summaryText}</ReactMarkdown>
                )}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {cta && cta.linkText ? (
        <div data-testid="StandardCard-footer" className={styles.cardFooter}>
          {cta.linkText}
        </div>
      ) : null}
    </div>
  );
};

CardContent.propTypes = StandardCardPropTypes;

CardContent.defaultProps = {
  headerText: null,
  summaryText: null,
  summaryRichText: null,
  image: null,
  imageExpand: false,
  cta: null,
  _id: null,
  className: null
};

function StandardCard({ _id, headerText, summaryText, image, imageExpand, cta, summaryRichText, className }) {
  return (
    <div data-testid="StandardCard" className={styles.cardWrap}>
      {cta && cta.href ? (
        <ElementLink
          href={cta.href}
          as={cta.as}
          linkText={cta.linkText}
          isModal={false}
          download={false}
          className={styles.cardLinkWrap}>
          <CardContent
            _id={_id}
            headerText={headerText}
            summaryText={summaryText}
            summaryRichText={summaryRichText}
            image={image}
            imageExpand={imageExpand}
            cta={cta}
            className={className}
          />
        </ElementLink>
      ) : (
        <CardContent
          _id={_id}
          headerText={headerText}
          summaryText={summaryText}
          summaryRichText={summaryRichText}
          image={image}
          imageExpand={imageExpand}
          cta={cta}
          className={className}
        />
      )}
    </div>
  );
}

StandardCard.propTypes = StandardCardPropTypes;

StandardCard.defaultProps = {
  headerText: null,
  summaryText: null,
  summaryRichText: null,
  image: null,
  imageExpand: false,
  cta: null,
  _id: null,
  className: null
};

export default StandardCard;
