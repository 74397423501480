import PropTypes from 'prop-types';
import AssetPropTypes from '../Asset/AssetPropTypes';

export default {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.shape(AssetPropTypes).isRequired,
  theme: PropTypes.string.isRequired
};
