import { useState, useEffect } from 'react';

const useLoginOrLogoutUrl = (doLogin) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    // eslint-disable-next-line no-undef
    setUrl(`https://www.dropbox.com/${doLogin ? 'login' : 'logout'}?cont=${window.location.href}`);
  }, [doLogin]);

  return url;
};

export default useLoginOrLogoutUrl;
