import has from 'lodash/has';
import {
  LOADING_COMPLETED_COURSES,
  LOAD_COMPLETED_COURSES,
  ADD_COMPLETED_COURSE,
  LOADING_COMPLETED_COURSE_TOPICS,
  LOAD_COMPLETED_COURSE_TOPICS,
  ADD_COMPLETED_COURSE_TOPIC,
  LOADING_COMPLETED_TOPICS,
  LOAD_COMPLETED_TOPICS
} from './courses.types';

export const initialState = {
  completed: {},
  completedCourseTopics: {},
  loading: false,
  loaded: false,
  loadingCourseTopics: false,
  loadedCourseTopics: false
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOADING_COMPLETED_COURSES: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case LOAD_COMPLETED_COURSES: {
      const { completed } = payload;

      return {
        ...state,
        loading: false,
        loaded: true,
        completed: {
          ...completed
        }
      };
    }

    case ADD_COMPLETED_COURSE: {
      const { course } = payload;
      const { completed } = state;
      if (!course) {
        return state;
      }

      const { courseId } = course;
      completed[courseId] = course;

      return {
        ...state,
        completed: {
          ...completed
        }
      };
    }

    case LOADING_COMPLETED_COURSE_TOPICS: {
      return {
        ...state,
        loadingCourseTopics: true,
        loadedCourseTopics: false
      };
    }

    case LOAD_COMPLETED_COURSE_TOPICS: {
      const { completedTopicsForCourse, courseId } = payload;

      const { completedCourseTopics } = state;

      completedCourseTopics[courseId] = completedTopicsForCourse;

      return {
        ...state,
        loadingCourseTopics: false,
        loadedCourseTopics: true,
        completedCourseTopics: {
          ...completedCourseTopics
        }
      };
    }

    case ADD_COMPLETED_COURSE_TOPIC: {
      const { topic, courseId } = payload;
      const { completedCourseTopics } = state;

      if (!topic) {
        return state;
      }

      if (!has(completedCourseTopics, courseId)) {
        completedCourseTopics[courseId] = {};
      }

      completedCourseTopics[courseId][topic.topicId] = topic;

      return {
        ...state,
        completedCourseTopics: {
          ...completedCourseTopics
        }
      };
    }

    case LOADING_COMPLETED_TOPICS: {
      return {
        ...state,
        loadingCourseTopics: true,
        loadedCourseTopics: false
      };
    }

    case LOAD_COMPLETED_TOPICS: {
      const { completedCourseTopics } = payload;

      return {
        ...state,
        loadingCourseTopics: false,
        loadedCourseTopics: true,
        completedCourseTopics: {
          ...completedCourseTopics
        }
      };
    }

    default:
      return state;
  }
}
