import each from 'lodash/each';
import get from 'lodash/get';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import reject from 'lodash/reject';
import { API } from 'aws-amplify';
import { getWinstonLogger } from '@last-rev/logging';

import {
  LOAD_COMPLETED_COURSES,
  ADD_COMPLETED_COURSE,
  LOADING_COMPLETED_COURSES,
  LOADING_COMPLETED_COURSE_TOPICS,
  LOAD_COMPLETED_COURSE_TOPICS,
  ADD_COMPLETED_COURSE_TOPIC,
  LOADING_COMPLETED_TOPICS,
  LOAD_COMPLETED_TOPICS
} from './courses.types';
import logger from '../../../logger';

const apiName = process.env.ENDPOINT_NAME;

const datadogLogger = getWinstonLogger({ path: 'src/redux/modules/courses', filename: 'courses.actions.js' });

export const loadCompletedCourses = () => async (dispatch, getState) => {
  const {
    user: { id: userId }
  } = getState();
  if (!userId) return;
  dispatch({
    type: LOADING_COMPLETED_COURSES
  });

  try {
    const path = '/courses/listbyuserid';
    const response = await API.get(apiName, path, {
      queryStringParameters: {
        user_id: userId
      }
    });
    const { items: completedCourses } = response;

    const completed = keyBy(completedCourses, 'courseId');

    dispatch({
      type: LOAD_COMPLETED_COURSES,
      payload: {
        completed
      }
    });
  } catch (error) {
    logger.error(error);
    datadogLogger.error(error);
    dispatch({
      type: LOAD_COMPLETED_COURSES,
      payload: {
        completed: {}
      }
    });
  }
};

export const completeCourse = (courseId) => async (dispatch, getState) => {
  const {
    user: { id: userId },
    courses: { completed }
  } = getState();
  if (!courseId || has(completed, courseId)) return;

  try {
    const path = '/courses/create';
    const course = await API.post(apiName, path, {
      body: {
        userId,
        courseId
      }
    });
    dispatch({
      type: ADD_COMPLETED_COURSE,
      payload: {
        course
      }
    });
  } catch (error) {
    logger.error(error);
    datadogLogger.error(error);
  }
};

export const setViewedCompletiondMessageForCourse = (courseId) => async (dispatch, getState) => {
  try {
    const {
      courses: { completed },
      user: { id: userId }
    } = getState();

    if (!userId || !courseId || !has(completed, courseId)) return;

    const path = '/courses/update';
    const result = await API.patch(apiName, path, {
      body: {
        userId,
        courseId,
        viewedCompletionMessage: true
      }
    });

    if (!result.success) throw Error('unsuccessful update');

    const course = get(completed, courseId);

    dispatch({
      type: ADD_COMPLETED_COURSE,
      payload: {
        course: {
          ...course,
          viewedCompletionMessage: true
        }
      }
    });
  } catch (err) {
    logger.error(err);
    datadogLogger.error(err);
  }
};

export const loadCompletedTopicsForCourse = (courseId) => async (dispatch, getState) => {
  try {
    const {
      user: { id: userId }
    } = getState();
    if (!userId) return null;
    if (!courseId) return null;
    dispatch({
      type: LOADING_COMPLETED_COURSE_TOPICS
    });

    const path = '/topics/listbyuserid';
    const response = await API.get(apiName, path, {
      queryStringParameters: {
        user_id: userId,
        course_id: courseId
      }
    });

    const { items: completedTopics } = response;
    const completedTopicsForCourse = keyBy(completedTopics, 'topicId');

    return dispatch({
      type: LOAD_COMPLETED_COURSE_TOPICS,
      payload: {
        courseId,
        completedTopicsForCourse
      }
    });
  } catch (error) {
    logger.error(error);
    datadogLogger.error(error);
    return dispatch({
      type: LOAD_COMPLETED_COURSE_TOPICS,
      payload: {
        courseId,
        completedTopicsForCourse: {}
      }
    });
  }
};

export const loadAllCompletedTopics = () => async (dispatch, getState) => {
  try {
    const {
      user: { id: userId }
    } = getState();
    if (!userId) return null;
    dispatch({
      type: LOADING_COMPLETED_TOPICS
    });

    const path = '/topics/listbyuserid';
    const response = await API.get(apiName, path, {
      queryStringParameters: {
        user_id: userId
      }
    });

    const { items: completedTopics } = response;
    const completedCourseTopics = {};
    each(completedTopics, (topic) => {
      if (!has(completedCourseTopics, topic.courseId)) {
        completedCourseTopics[topic.courseId] = {};
      }
      completedCourseTopics[topic.courseId][topic.topicId] = topic;
    });

    return dispatch({
      type: LOAD_COMPLETED_TOPICS,
      payload: {
        completedCourseTopics
      }
    });
  } catch (error) {
    logger.error(error);
    datadogLogger.error(error);
    return dispatch({
      type: LOAD_COMPLETED_TOPICS,
      payload: {
        completedCourseTopics: {}
      }
    });
  }
};

export const completeTopicForCourse = (topicId, courseId, courseTopics) => async (dispatch, getState) => {
  try {
    const {
      user: { id: userId },
      courses: { completedCourseTopics }
    } = getState();

    if (!topicId) return;

    const completedTopicsForCourse = get(completedCourseTopics, courseId, {});

    if (has(completedTopicsForCourse, topicId)) return;

    const courseTopicIds = map(courseTopics, '_id');
    const uncompletedTopicIds = reject(courseTopicIds, (courseTopicId) => has(completedTopicsForCourse, courseTopicId));
    const path = '/topics/create';
    const topic = await API.post(apiName, path, {
      body: {
        userId,
        topicId,
        courseId
      }
    });
    dispatch({
      type: ADD_COMPLETED_COURSE_TOPIC,
      payload: {
        courseId,
        topic
      }
    });
    if (uncompletedTopicIds.length === 1 && uncompletedTopicIds[0] === topicId) {
      dispatch(completeCourse(courseId));
    }
  } catch (error) {
    logger.error(error);
    datadogLogger.error(error);
  }
};
