import React from 'react';
import CategoriesPropTypes from './CategoriesPropTypes';
import Asset from '../Asset';

function Categories({ _id, _contentTypeId, name, image, theme }) {
  return (
    <div data-testid="Categories" className={`section theme-${theme}`}>
      <div data-testid="Categories" className="container">
        <div className="row">
          <div className="col-4 col-sm text-left">
            <h1>Name: {name} (H1)</h1>
            <h2>Theme: {theme} (H2)</h2>
            <h3>ID: {_id} (h3)</h3>
            <h4>Content Type ID: {_contentTypeId} (h4)</h4>
          </div>
          <div className="col-4 col-md-8 text-center">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Asset {...image} imageWrapClassName="imageCircle" />
          </div>
        </div>
      </div>
    </div>
  );
}

Categories.propTypes = CategoriesPropTypes;

Categories.defaultProps = {};

export default Categories;
