/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import AssetPropTypes from '../Asset/AssetPropTypes';
import { ElementLinkPropTypes } from '../ElementLink/ElementLink';
import BasicCard from './BasicCard';
import StandardCard from './StandardCard';
import VideoCard from './VideoCard';
import RichTextParserPropTypes from '../RichTextParser/RichTextParserPropTypes';

export const ModuleCardPropTypes = {
  _id: PropTypes.string,
  headerText: PropTypes.string,
  summaryText: PropTypes.string,
  summaryRichText: PropTypes.shape(RichTextParserPropTypes),
  image: PropTypes.shape(AssetPropTypes),
  imageExpand: PropTypes.bool,
  cta: PropTypes.shape(ElementLinkPropTypes),
  cardStyle: PropTypes.string,
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  subjects: PropTypes.arrayOf(PropTypes.string)
};

function ModuleCard({
  _id,
  headerText,
  summaryText,
  image,
  imageExpand,
  cta,
  cardStyle,
  summaryRichText,
  className,
  tags,
  subjects
}) {
  const cardStyles = ['standard', 'basic', 'video'];
  return (
    <div data-testid="ModuleCard">
      {cardStyle === 'standard' || !cardStyles.includes(cardStyle) ? (
        <StandardCard
          _id={_id}
          headerText={headerText}
          summaryText={summaryText}
          summaryRichText={summaryRichText}
          image={image}
          imageExpand={imageExpand}
          cta={cta}
          className={className}
        />
      ) : null}
      {cardStyle === 'basic' ? (
        <BasicCard
          _id={_id}
          headerText={headerText}
          summaryText={summaryText}
          summaryRichText={summaryRichText}
          image={image}
          imageExpand={imageExpand}
          className={className}
        />
      ) : null}
      {cardStyle === 'video' ? (
        <VideoCard
          _id={_id}
          headerText={headerText}
          summaryText={summaryText}
          image={image}
          imageExpand={imageExpand}
          cta={cta}
          className={className}
          tags={tags}
          subjects={subjects}
        />
      ) : null}
    </div>
  );
}

ModuleCard.propTypes = ModuleCardPropTypes;

ModuleCard.defaultProps = {
  headerText: null,
  summaryText: null,
  summaryRichText: null,
  image: null,
  imageExpand: false,
  cta: null,
  _id: null,
  cardStyle: 'standard',
  className: '',
  tags: null,
  subjects: null
};

export default ModuleCard;
