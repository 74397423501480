import camelCase from 'lodash/camelCase';

export const getLanguageDisplayName = (language, t) => {
  if (!language) return null;

  const commonName = `common:${camelCase(`language-name-${language}`)}`;
  const languageDisplayName = t(commonName);

  if (languageDisplayName === commonName) return language;

  return languageDisplayName;
};

export const getCommonString = (str, t) => {
  return t(`common:${camelCase(str)}`);
};
