import { LOADING_USER_PROFILE, LOADED_USER_PROFILE, NOT_LOGGED_IN } from './user.types';

export const initialState = {
  id: null,
  firstName: null,
  lastInitial: null,
  loading: false,
  loaded: false
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOADING_USER_PROFILE: {
      return {
        ...initialState,
        loading: true
      };
    }

    case LOADED_USER_PROFILE: {
      const { id, firstName, lastInitial } = payload;
      return {
        ...state,
        id,
        firstName,
        lastInitial,
        loading: false,
        loaded: true
      };
    }

    case NOT_LOGGED_IN:
      return {
        ...initialState,
        loaded: true
      };
    default:
      return state;
  }
}
