/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import cx from 'clsx';
import { connect } from 'react-redux';
import get from 'lodash/get';
import keys from 'lodash/keys';
import map from 'lodash/map';
import intersection from 'lodash/intersection';
import styles from './CourseCard.module.scss';
import Asset from '../Asset';
import AssetPropTypes from '../Asset/AssetPropTypes';
import ElementLink, { ElementLinkPropTypes } from '../ElementLink/ElementLink';

const mapStateToProps = (state) => ({
  completedCourseTopics: state.courses.completedCourseTopics,
  completedCourseTopicsLoaded: state.courses.loadedCourseTopics
});

export const CourseCardPropTypes = {
  _id: PropTypes.string,
  _contentTypeId: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  summaryText: PropTypes.string,
  image: PropTypes.shape(AssetPropTypes),
  cta: PropTypes.shape(ElementLinkPropTypes),
  fieldClassNames: PropTypes.object,
  topics: PropTypes.arrayOf(PropTypes.object)
};

function CourseCard({
  _id,
  /* _contentTypeId, */ headerText,
  summaryText,
  image,
  cta,
  fieldClassNames,
  topics,
  // redux state below
  completedCourseTopics,
  completedCourseTopicsLoaded
}) {
  const { lang } = useTranslation();
  const [percentCompleted, setPercentCompleted] = useState(0);

  useEffect(() => {
    if (topics?.length && completedCourseTopicsLoaded) {
      const courseTopicIds = map(topics, '_id') || [];
      const completedIds = intersection(keys(get(completedCourseTopics, _id, {})), courseTopicIds);
      const completedLength = completedIds.length;
      const percent = Math.round(100 * (completedLength / topics.length));
      setPercentCompleted(percent);
    }
  }, [_id, topics, completedCourseTopicsLoaded, completedCourseTopics]);

  const isSvg = image?.url?.split('.').pop() === 'svg';

  return (
    <div data-testid="CourseCard" className={cx(styles[fieldClassNames.cardWrap], styles.cardWrap)} key={_id}>
      <ElementLink
        href={cta.href}
        as={cta.as}
        linkText={cta.linkText}
        isModal={false}
        download={false}
        trackingId={cta.href.includes('virtual-training') ? `webinar_card_${cta.href}` : `course_card_${cta.href}`}
        className={styles.cardLinkWrap}>
        <div className={cx(styles.card, !isSvg && styles.notSvg)} data-testid="CourseCard-LinkContent">
          {image ? <Asset _id={_id} imageWrapClassName={styles.imageWrap} {...image} isInlineSvg={isSvg} /> : null}
          {percentCompleted > 0 && percentCompleted < 100 && <div className={styles.clock} />}
          {headerText || summaryText ? (
            <div className={styles.cardBody} data-testid="CourseCard-TextWrap">
              {headerText ? (
                <div className={styles.cardTitleWrap} {...sidekick(_id, null, null, 'Header Text')}>
                  <h3
                    className={cx(styles[fieldClassNames.cardTitle], styles.cardTitle)}
                    data-testid="CourseCard-title"
                    lang={lang}>
                    {headerText}
                  </h3>
                </div>
              ) : null}
              {summaryText ? (
                <div className={styles.cardTextWrap} {...sidekick(_id, null, null, 'Summary Text')}>
                  <p className={styles.cardText} data-testid="CourseCard-summaryText">
                    {summaryText}
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}

          {cta && cta.linkText ? (
            <div className={styles.cardFooter} data-testid="CourseCard-cta">
              {cta.linkText}
            </div>
          ) : null}
        </div>
      </ElementLink>
    </div>
  );
}

CourseCard.propTypes = CourseCardPropTypes;

CourseCard.defaultProps = {
  summaryText: null,
  image: null,
  cta: null,
  _id: null,
  _contentTypeId: null,
  fieldClassNames: {}
};

export default connect(mapStateToProps)(CourseCard);
