/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './SearchInput.module.scss';
import Asset from '../Asset';
import ElementLink from '../ElementLink';
import coveoSearch from '../../apis/coveo';

export const SearchInputPropTypes = {
  size: PropTypes.oneOf(['sm', 'lg']),
  placeholder: PropTypes.string,
  onSearch: PropTypes.func
};

export const SearchInputUIPropTypes = {
  ...SearchInputPropTypes,
  setValue: PropTypes.func,
  value: PropTypes.string
};

const processSuggestions = (userInput, suggestions) => {
  const cleaned = suggestions.map((s) => {
    return s.title.split('|')[0].toLowerCase().replaceAll(/\?/g, '');
  });
  const distinct = new Set(cleaned);

  return [userInput, ...distinct];
};

// TODO: right now we're doing an actual search because we need some search data in order to bootstrap the
// Coveo ML model; change this to a request for suggestions later.
const getSearchSuggestions = async (value) => {
  const rawResponse = await coveoSearch(value);
  const response = await rawResponse.json();
  return processSuggestions(value, response.results);
};

export const SearchSuggestionPropTypes = {
  children: PropTypes.element.isRequired,
  suggestion: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired
};
const SearchSuggestion = ({ children, suggestion, active, onSelected }) => {
  return (
    <a
      href={`/search?q=${suggestion}`}
      className={cx({ [styles.searchSuggestion]: true, [styles.activeSuggestion]: active })}
      onClick={(e) => {
        e.preventDefault();
        onSelected();
        return false;
      }}>
      {children}
    </a>
  );
};

SearchSuggestion.propTypes = SearchSuggestionPropTypes;

export function SearchInputUI({ size, value, setValue, placeholder, onSearch }) {
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const userEntered = suggestions && suggestions[0];
  const handleSearchChange = (event) => {
    const newSearch = event.target.value;
    setValue(newSearch);

    if (newSearch.length > 2) {
      getSearchSuggestions(newSearch).then((s) => {
        setSuggestions(s);
      });
    } else {
      setSuggestions([]);
    }
  };

  const handleSearchSubmit = () => {
    onSearch(value);
  };

  useEffect(() => {
    // When the selected suggestion changes, set the current value to the suggestion
    if (suggestions && suggestions.length && suggestions[selectedSuggestion]) {
      setValue(() => suggestions[selectedSuggestion]);
    }
  }, [selectedSuggestion]);

  const parentRef = useRef(null);

  useEffect(() => {
    // We need to listen to the global page onclick for blur purposes,
    // because the parent of an absolute child gets onBlur'd when the child is clicked.
    const onPageClick = (e) => {
      const { target } = e;
      if (!parentRef.current.contains(target)) {
        setFocused(false);
      }
    };
    /* global window */
    window.addEventListener('click', onPageClick);
    return () => window.removeEventListener('click', onPageClick);
  }, [parentRef]);

  const buttonStyle = cx({
    [styles.btnSearchSmall]: size === 'sm',
    [styles.btnSearchLarge]: size === 'lg'
  });
  return (
    <div
      ref={parentRef}
      data-testid="SearchInput"
      className={cx({ [styles.searchInputInnerWrap]: true, [styles.sm]: size === 'sm' })}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      onFocus={() => {
        setFocused(true);
      }}>
      <label className={styles.searchInputGroup} htmlFor="header-search-bar">
        <input
          className={cx({
            [styles.searchInputSmall]: size === 'sm',
            [styles.searchInputLarge]: size === 'lg'
          })}
          placeholder={placeholder}
          autoComplete="off"
          onChange={handleSearchChange}
          onKeyDown={(event) => {
            switch (event.key) {
              case 'Enter':
                handleSearchSubmit();
                setFocused(false);
                break;
              case 'Down':
              case 'ArrowDown':
                setSelectedSuggestion((prev) => (prev + 1) % suggestions.length);
                break;
              case 'Up':
              case 'ArrowUp':
                setSelectedSuggestion((prev) => (prev + suggestions.length - 1) % suggestions.length);
                break;
              case 'Esc':
              case 'Escape':
                setFocused(false);
                break;
              default:
                // Any other key, assume we've regained focus
                setFocused(true);
                break;
            }
          }}
          value={value}
          type="search"
          id="header-search-bar"
        />
        <span className={styles.searchInputGroupIconWrap}>
          <ElementLink
            href="/"
            linkText=""
            onClick={(e) => {
              e.preventDefault();
              setValue('');
              return false;
            }}
            className={cx(buttonStyle, styles.middleButton)}>
            <Asset
              url={value.length > 0 && hovered ? '/images/assets/icon-close-x.svg' : '/images/assets/blank.svg'}
              title={placeholder}
              isInlineSvg
            />
          </ElementLink>
          <ElementLink
            href="/search"
            onClick={(e) => {
              e.preventDefault();
              handleSearchSubmit();
              return false;
            }}
            className={buttonStyle}
            linkText={placeholder}
            isModal={false}
            trackingId={"global_header_search"}
            download={false}>
            <Asset url="/images/assets/icon-search.svg" title={placeholder} isInlineSvg />
          </ElementLink>
        </span>
      </label>
      {focused && suggestions && suggestions.length > 1 && (
        <div className={`${styles.searchSuggestionContainer}`}>
          {suggestions.map((s, idx) => {
            // The first suggestion is always what they've typed in
            if (idx === 0) {
              return null;
            }

            const searchParts = s.split(userEntered);
            const highlightedSearch = searchParts.map((text, index) => {
              if (index < searchParts.length - 1) {
                return (
                  <>
                    {text}
                    <b>{userEntered}</b>
                  </>
                );
              }
              return <>{text}</>;
            });

            return (
              <SearchSuggestion
                key={s}
                active={idx === selectedSuggestion}
                suggestion={s}
                currentSearch={value}
                onSelected={() => {
                  setValue(s);
                  onSearch(s);
                }}>
                {highlightedSearch}
              </SearchSuggestion>
            );
          })}
        </div>
      )}
    </div>
  );
}

function SearchInput(props) {
  const [value, setValue] = useState('');
  return <SearchInputUI {...{ ...props, value, setValue }} />;
}

SearchInput.propTypes = SearchInputPropTypes;

SearchInput.defaultProps = {
  size: 'sm',
  placeholder: '',
  onSearch: () => {}
};

SearchInputUI.propTypes = SearchInputUIPropTypes;

SearchInputUI.defaultProps = {
  ...SearchInput.defaultProps,

  setValue: () => {},
  value: undefined
};

export default SearchInput;
