/* eslint-disable react/prop-types */
import React, { createContext, useContext } from 'react';

const ComponentLookupContext = createContext(() => {});

export const ComponentLookupContextProvider = ({ componentLookup = () => {}, children }) => {
  return <ComponentLookupContext.Provider value={componentLookup}>{children}</ComponentLookupContext.Provider>;
};

export const useComponentLookupContext = () => {
  return useContext(ComponentLookupContext);
};

export default ComponentLookupContext;
