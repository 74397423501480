import has from 'lodash/has';
import isPlainObject from 'lodash/isPlainObject';

const getArgs = (iArgs) => iArgs.filter((arg) => isPlainObject(arg));

const getOptions = (iArgs) => iArgs.reduce((a, b) => ({ ...b, ...a }), {});

const isOGSimple = (option) => has(option, 'name') && option.name.startsWith('og:') && typeof option.value === 'string';

const isOGComplex = (option) =>
  has(option, 'name') && option.name.startsWith('og:') && isPlainObject(option.value) && option.value.url;

const isTwitter = (option) =>
  has(option, 'name') && option.name.startsWith('twitter:') && typeof option.value === 'string';

const isTwitterComplex = (option) =>
  has(option, 'name') && option.name.startsWith('twitter:') && isPlainObject(option.value) && option.value.url;

const isComplex = (option) => isPlainObject(option.value) && option.value.url;

const getTags = (options) =>
  Object.keys(options).reduce((acc, key) => {
    if (isOGSimple(options[key])) acc.push({ property: options[key].name, content: options[key].value });
    else if (isOGComplex(options[key])) acc.push({ property: options[key].name, content: options[key].value.url });
    else if (isTwitter(options[key])) acc.push({ name: options[key].name, content: options[key].value });
    else if (isTwitterComplex(options[key])) acc.push({ name: options[key].name, content: options[key].value.url });
    else if (isComplex(options[key])) acc.push({ name: options[key].name, content: options[key].value.url });
    else acc.push({ name: options[key].name, content: options[key].value.replace(',nofollow', ',follow') });
    return acc;
  }, []);

const getMetaTags = (...args) => {
  const validArgs = getArgs(args);
  const options = getOptions(validArgs);
  const tags = getTags(options);
  return tags;
};

export default getMetaTags;
