import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import ElementWistiaVideo from '../ElementWistiaVideo';

export const ElementVideoPropTypes = {
  _id: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  isYouTube: PropTypes.bool,
  playsInline: PropTypes.bool
};

function ElementVideo({ _id, videoId, isYouTube, playsInline }) {
  return (
    <div data-testid="ElementVideo" {...sidekick(_id, null, null, 'Element - Video')}>
      <div className="video-card">
        {videoId ? (
          <div data-testid="ElementWistiaVideoWrap" className="card-img-top-wrap">
            <ElementWistiaVideo videoId={videoId} isYouTube={isYouTube} playsInline={playsInline} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

ElementVideo.propTypes = ElementVideoPropTypes;
ElementVideo.defaultProps = {
  isYouTube: false,
  playsInline: false
};

export default ElementVideo;
