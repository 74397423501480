/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import snakeCase from 'lodash/snakeCase';
import PropTypes from 'prop-types';
import { useCourseTopicContext } from '../../CourseTopicContext';

export const ElementLinkPropTypes = {
  _id: PropTypes.string,
  _contentTypeId: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  as: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  icon: PropTypes.string,
  target: PropTypes.string,
  isModal: PropTypes.bool,
  download: PropTypes.bool,
  trackingId: PropTypes.string,
  style: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func
};

const NextLinkPropTypes = {
  _id: PropTypes.string,
  _contentTypeId: PropTypes.string,
  href: PropTypes.string.isRequired,
  as: PropTypes.string,
  className: PropTypes.string,
  trackingId: PropTypes.string,
  children: PropTypes.node.isRequired,
  displayText: PropTypes.string
};

const StandardLinkPropTypes = {
  _id: PropTypes.string,
  _contentTypeId: PropTypes.string,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  className: PropTypes.string,
  isModal: PropTypes.bool.isRequired,
  download: PropTypes.bool.isRequired,
  trackingId: PropTypes.string,
  children: PropTypes.node.isRequired,
  displayText: PropTypes.string,
  onClick: PropTypes.func
};

const StandardLink = ({
  _id,
  _contentTypeId,
  target,
  href,
  className,
  isModal,
  download,
  trackingId,
  children,
  displayText,
  onClick
}) => {
  if (isModal) {
    /* onClick = () => {
       // TODO: handle opening modal
     }; */
  }

  return (
    <a
      data-uxa-log={trackingId}
      data-uxa-interactions="click shown"
      onClick={onClick}
      className={className}
      href={href}
      target={target}
      aria-label={target && target !== '_self' && `Open ${displayText} in a new tab`}
      rel="noopener noreferrer"
      data-testid="ElementLink"
      download={download}
      {...sidekick(_id, null, _contentTypeId, displayText)}>
      {children}
    </a>
  );
};

StandardLink.propTypes = StandardLinkPropTypes;

StandardLink.defaultProps = {
  _id: null,
  displayText: null,
  _contentTypeId: null,
  className: null,
  target: null,
  trackingId: null,
  onClick: null
};

const NextLink = ({ _id, _contentTypeId, href, as, className, trackingId, children, displayText }) => {
  const { lang } = useTranslation();

  return (
    <Link locale={lang === 'pt-BR' ? 'pt-br' : lang} href={href} as={as}>
      <a
        className={className}
        data-testid="ElementLink"
        data-uxa-log={trackingId}
        data-uxa-interactions="click shown"
        {...sidekick(_id, null, _contentTypeId, displayText)}>
        {children}
      </a>
    </Link>
  );
};

NextLink.propTypes = NextLinkPropTypes;

NextLink.defaultProps = {
  _id: null,
  _contentTypeId: null,
  className: null,
  as: null,
  trackingId: null,
  displayText: null
};

const getFullClassName = ({ style, className, icon }) => {
  if (!style && !className && !icon) return null;
  return `${style ? `link_${style}` : ''} ${className || ''} ${icon ? `icon_${snakeCase(icon)}` : ''}`;
};

const getLastPathSegment = (path) => {
  if (!path) return path;

  const regex = /\/([^/]+)$/; // Matches the last slash and everything after it

  const match = path.match(regex);
  if (match) {
    const result = match[1]; // The captured group contains what's after the last slash
    return result;
  }
  return path;
};

function ElementLink({
  _id,
  _contentTypeId,
  className,
  href: initialHref,
  as: initialAs,
  target,
  linkText,
  icon,
  isModal,
  download,
  trackingId,
  style,
  children,
  onClick
}) {
  const { getTopicPath } = useCourseTopicContext();
  let href = initialHref;
  let as = initialAs;

  const fullClassName = getFullClassName({ style, className, icon });

  // TODO: is this enough of a check? Someone could pass in
  // my.website.com/something, and this should be handled
  const isInternal = href && href.startsWith('/');
  const isTargetSelf = !target || target === '_self';

  // if the window should change URl without refreshing the page,
  // use Next/Link
  const useNextLink = isInternal && !download && !isModal && !onClick && isTargetSelf;

  if (useNextLink) {
    const slug = getLastPathSegment(as);
    const path = getTopicPath(slug);
    if (as && as === `/self-guided-learning/${slug}`) {

      if (path && path !== initialAs) {
        as = as.replace(`/${slug}`, path);
        href = href.replace(`[topicslug]`, '[slug]/[topicslug]');
      }
    }

   
    /*if (trackingId && trackingId.endsWith('/[slug]')) {
      trackingId = trackingId.replace('/[slug]', `${path}`);
    }
    if(trackingId && path && trackingId.endsWith('/[topicslug]')) {
      trackingId = trackingId.replace('/[topicslug]', `${path}`);
    }*/

    if (trackingId && path) {
      if (trackingId.endsWith('/[slug]')) {
        trackingId = trackingId.replace('/[slug]',`${path}`);
      }
      if (trackingId.endsWith('/[topicslug]')) {
        trackingId = trackingId.replace('/[topicslug]',`${path}`);
      }
    }

    return (
      <NextLink
        _id={_id}
        _contentTypeId={_contentTypeId}
        href={as === '/home' ? '/' : href}
        as={as === '/home' ? '/' : as}
        className={fullClassName}
        trackingId={trackingId}
        target={target}
        isModal={isModal}
        download={download}
        displayText={linkText}>
        {children || linkText}
      </NextLink>
    );
  }

  return (
    <StandardLink
      _id={_id}
      _contentTypeId={_contentTypeId}
      target={target}
      href={href}
      onClick={onClick}
      className={fullClassName}
      aria-label={target && !isTargetSelf && `Open ${linkText} in a new tab`}
      isModal={isModal}
      download={download}
      trackingId={trackingId}
      displayText={linkText}>
      {children || linkText}
    </StandardLink>
  );
}

ElementLink.propTypes = ElementLinkPropTypes;

ElementLink.defaultProps = {
  _id: null,
  _contentTypeId: null,
  className: null,
  as: null,
  target: null,
  icon: null,
  trackingId: null,
  style: null,
  children: null,
  download: false,
  isModal: false,
  onClick: null
};

export default ElementLink;
